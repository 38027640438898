import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import Isvg from 'react-inlinesvg';
import arrowDown from '../../images/svg/arrowDown.svg';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

import {
    Container, Row, Col, Button, FormGroup,
    Input, NavItem
} from 'reactstrap';
import MyButton from '../myButton';
import { renderField, renderDateField, renderCheckField, renderTimeField, renderSelectField } from './fields'

const required = value => value ? undefined : "Required"


const form = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    console.log(pristine, submitting);
    const buttonText = "JETZT RESERVIEREN";

    return (
        <form onSubmit={handleSubmit}>
            <FormGroup>
                <Field validate={[required]} component={renderField} type="text" name="name" placeholder="Name / Vorname:" />

            </FormGroup>

            <Row>
                <Col xl="6" lg="6" md="6" xs="12">
                    <FormGroup>
                        <Field validate={[required]} component={renderField} type="text" placeholder="Telefon für Rückfragen:" name="phone" />
                    </FormGroup>
                </Col>
                <Col xl="6" lg="6" md="6" xs="12">
                    <FormGroup>
                        <Field validate={[required]} component={renderField} type="email" placeholder="Email:" name="email" />
                    </FormGroup>
                </Col>
                <Col xl="12" lg="12" md="12" xs="12">
                    <div className="d-flex input lastInput mb-4">
                        <p>Wir möchten gerne</p>
                        <FormGroup>
                            <Field validate={[required]} component={renderField} type="text" name="reserve" />
                        </FormGroup>
                        <p>Tisch reservieren</p>
                    </div>

                </Col>
                <Col xl="6" lg="6" md="6" xs="12">
                    <FormGroup>
                        <Field validate={[required]} component={renderDateField} name="date" placeholder="Choose date" />
                    </FormGroup>
                    <div className="d-flex input wrappInput mb-4">

                        <p>Wir kommen mit</p>

                        <FormGroup>
                            <Field validate={[required]} component={renderField} name="people" type="text" />
                        </FormGroup>


                        <p>Personen</p>

                    </div>
                    <div className="d-flex input wrappInput">

                        <p>Wir benötigen</p>

                        <FormGroup>
                            <Field validate={[required]} component={renderField} name="needCup" type="text" />
                        </FormGroup>

                        <p>Becher</p>

                    </div>


                </Col>
                <Col xl="6" lg="6" md="6" xs="12">
                    <div className="d-flex inputSelect center-align">
                        <p>Uhrzeit von</p>
                        <FormGroup>
                            <Field validate={[required]} component={renderTimeField} type="text" name="from">
                            </Field>
                        </FormGroup>
                        <p>bis</p>
                        <FormGroup>
                            <Field validate={[required]} component={renderTimeField} type="text" name="to">
                            </Field>
                        </FormGroup>
                    </div>
                    <div className="d-flex input lastInput mb-4">
                        <p>Wir benötigen</p>
                        <FormGroup>
                            <Field validate={[required]} component={renderField} type="text" name="needPlate" />
                        </FormGroup>
                        <p>Teller</p>
                    </div>
                    <div className="d-flex input lastInput">
                        <p>Wir benötigen</p>
                        <FormGroup>
                            <Field validate={[required]} component={renderField} type="text" name="needNapkins" />
                        </FormGroup>
                        <p>Servietten</p>
                    </div>
                </Col>

                <Col xl="6" lg="6" md="6" xs="12">
                    <div className="check d-block d-md-flex d-lg-flex mt-4">
                        <p>Wir essen bei Ihnen (eines unserer Geburtstagsmenüs)</p>
                    </div>
                </Col>
                <Col xl="6" lg="6" md="6" xs="12">
                    <Field component={renderCheckField} placeholder="Ja/Nein" name="eat" />
                </Col>
                <div className="multiWrapper">
                    <MyButton buttonText={buttonText}></MyButton>
                </div>
            </Row>

        </form>
    )
}
export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
