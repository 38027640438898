import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';

import Navbar from '../containers/navbar.js';
import Footer from '../containers/footer.js';


import HeaderBackground2 from '../images/header2BG.png';
import arrowDown from '../images/svg/arrowDown.svg';
import MyButton from '../containers/myButton';
import DefaultLayout from '../containers/defaultLayout';
import MobileBackground from '../images/mobileBG.png';
import ServicePicture2 from '../images/service2.png';

import {
    Container, Row, Col, Button, FormGroup,
    Input, NavItem
} from 'reactstrap';
import SchoolClassForm from '../containers/forms/schoolClassForm';

export class fond extends Component {
    constructor(props) {
        super(props);

        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);



    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    render() {
        const buttonText = "JETZT RESERVIEREN";

        return (
            <>
                <div className="header2">
                    {this.state.width > 991 ? <img src={HeaderBackground2} className="headerBG" /> : <img src={MobileBackground} className="headerBG"></img>}
                </div>
                <Container className="aboutPage">
                    <Row noGutters className="d-block d-md-flex d-lg-flex justify-content-between universalTextPage align-items-center">
                        <div>
                            <h1>Schulklassen</h1>
                        </div>
                        <div className="bredkramb">
                            <NavItem>
                                <Link to="/">Startseite</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/"> Schulklassen</Link>
                            </NavItem>
                        </div>
                    </Row>
                    <Row>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <div className="text" dangerouslySetInnerHTML={{__html: this.props.pages['Schulklassen'].shortDescription}}>

                            </div>
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <img src={this.props.pages['Schulklassen'].image} className="img-fluid mt-4" />
                        </Col>
                    </Row>
                    <div dangerouslySetInnerHTML={{__html: this.props.pages['Schulklassen'].content}}>


                    </div>

                    <div className="offer">
                        <h2>Anmeldeformular:</h2>
                    </div>
                    <div className="fondInput inputi">
                        <SchoolClassForm onSubmit={(data) => {
                            this.props.core.school(data, () => {
                                this.setState({
                                    _done: true
                                })
                            })
                        }}></SchoolClassForm>
                        {this.state._done ?
                            <div class="alert alert-success mt-3 mb-5" role="alert">
                                Die Nachricht wurde erfolgreich gesendet!
                     </div> : null}
                    </div>

                </Container>
            </>
        )
    }
}

export default DefaultLayout(fond)
