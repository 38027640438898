import React, { Component } from 'react'
import Navbar from '../containers/navbar';
import HeaderBackground2 from '../images/header2BG.png';
import { Link } from 'react-router-dom';
import GoogleMap from '../containers/map.js';
import Contact from '../containers/contact';
import Footer from '../containers/footer.js';
import AboutPicture from '../images/aboutPicture.png';
import DefaultLayout from '../containers/defaultLayout';
import MobileBackground from '../images/mobileBG.png';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Input,
    NavItem
} from 'reactstrap';
import 'moment/locale/de'  // without this line it didn't work

const moment = require('moment')
moment.locale('de')
class Öffnungszeiten extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        this.state = { width: 0, height: 0, workingTime: [] };
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.props.core.fetchWorkingTimeBySeasion((data) => {
           //console.log(data);

            for (let i = 0; i < data.length; i++) {
                let obj = {};
                //console.log(data[i]);
                let daysInMonth = new Date(data[i].name.split('/')[1], data[i].name.split('/')[0], 0).getDate();
                //console.log(daysInMonth);


                for (let j = 1; j <= daysInMonth; j++) {

                    let date = data[i].name.split('/')[0] +  "/"  + j   + "/" + data[i].name.split('/')[1]
                    //console.log(date);


                    if (!obj[date]) {
                        obj[date] = [];
                    }
                    let found = false;
                    for(let k=0;k<data[i].values.length;k++){

                        //console.log(data[i].values[k].date, date);

                        if (data[i].values[k].date == date){
                            found = true;
                            obj[data[i].values[k].date].push(data[i].values[k]);
                        }

                    }

                    if (!found){
                        obj[date] = [{date: date, text: 'Geschlossen'}]
                    }




                    //obj[data[i].values[j].date].push(data[i].values[j]);
                }

                data[i].values = Object.values(obj);
            }
            console.log(data);

            this.setState({ workingTime: data })
        })
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    render() {

        return (
            <>
                <div className="header2">
                    {this.state.width > 991 ? <img src={HeaderBackground2} className="headerBG" /> : <img src={MobileBackground} className="headerBG"></img>}
                </div>
                <Container className="priceListPage">
                    <Row noGutters className="d-block d-md-flex d-lg-flex justify-content-between universalTextPage align-items-center">
                        <div>
                            <h1>Öffnungszeiten</h1>
                        </div>
                        <div className="bredkramb">
                            <NavItem>
                                <Link to="/"> Startseite</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/"> Eintrittspreise</Link>
                            </NavItem>
                        </div>
                    </Row>
                    {
                        this.state.workingTime.map((item, idx) => {
                            const date = item.name.split('/');
                            const year = date[1];
                            const mjeseci = date[0];
                            const mjesec = moment().month(mjeseci - 1).format("MMM");

                            return (
                                <>
                                    <Row className="d-flex justify-content-center">
                                        <Col xl="3">
                                            <div className="universalTitle mb-5">
                                                <h3>{mjesec}&nbsp;{year}</h3>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col xl="4" lg="4" md="4" xs="4">
                                            <h5>Tag</h5>
                                        </Col>
                                        <Col xl="4" lg="4" md="4" xs="4">
                                            <h5>Beschreibung</h5>
                                        </Col>
                                        <Col xl="2" lg="4" md="4" xs="4" className="offset-xl-2">
                                            <Row>
                                                <Col xl="6" lg="6" md="6" xs="6">
                                                    <h5>Von</h5>
                                                </Col>
                                                <Col xl="6" lg="6" md="6" xs="6">
                                                    <h5>Bis</h5>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {item.values.map((item2, idx2) => {
                                        //const weekDayName =  moment(item2.date).format('dddd');
                                        return (
                                            <Row noGutters className="silverRow">
                                                {
                                                    item2.map((item3, idx3) => {
                                                        return (
                                                            <>
                                                            <Col xl="4" lg="4" md="4" xs="4">
                                                                {idx3 == 0 ? <p>{moment(item3.date).format('dddd')} &nbsp; <span>{moment(item3.date).format("DD/MM/YYYY")}</span></p> : null}
                                                            </Col>
                                                            <Col xl="4" lg="4" md="4" xs="4">
                                                                <p>{item3.text}</p>
                                                            </Col>
                                                            <Col xl="2" lg="4" md="4" xs="4" className="offset-xl-2">
                                                                <Row>
                                                                    <Col xl="6" lg="6" md="6" xs="6">
                                                                        <p>{item3.from}</p>
                                                                    </Col>
                                                                    <Col xl="6" lg="6" md="6" xs="6">
                                                                        <p className="last-parahraph">{item3.to}</p>
                                                                    </Col>
                                                                </Row>

                                                            </Col>
                                                            </>
                                                        )
                                                    })
                                                }


                                            </Row>
                                        )
                                    })}

                                </>
                            )

                        })

                    }



                </Container>


                <GoogleMap {...this.props} />
                <Contact {...this.props} />
            </>
        )
    }
}

export default DefaultLayout(Öffnungszeiten)
