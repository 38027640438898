import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';

import Navbar from '../containers/navbar.js';
import Footer from '../containers/footer.js';
import Contact from '../containers/contact.js';


import HeaderBackground2 from '../images/header2BG.png';
import arrowDown from '../images/svg/arrowDown.svg';
import DefaultLayout from '../containers/defaultLayout';
import MobileBackground from '../images/mobileBG.png';

import {
    Container, Row, Col, Button, FormGroup,
    Input, NavItem
} from 'reactstrap';

export class dataProtection extends Component {
    constructor(props) {
        super(props);

        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);



    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    render() {
        return (
            <>
                <div className="header2">
                {this.state.width > 991 ?
                    <img src={HeaderBackground2} className="headerBG"/> : <img src={MobileBackground} className="headerBG"></img>}
                </div>
                <Container className="aboutPage">
                <Row noGutters className="d-block d-md-flex d-lg-flex justify-content-between universalTextPage align-items-center">
                        <div>
                            <h1>Datenschutzerklärung</h1>
                        </div>
                        <div className="bredkramb">
                            <NavItem>
                                <Link to="/">Startseite</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/"> Datenschutzerklärung</Link>
                            </NavItem>
                        </div>
                    </Row>
                    <div className="text">
                        <p>Wir freuen uns über Ihren Besuch auf unseren Webseiten. Nachfolgend möchten wir Sie über den Umgang mit Ihren Daten gem. Art. 13 Datenschutzgrundverordnung (DSGVO) informieren</p>
                        <div className="offer">
                            <h2>Verantwortlicher</h2>
                        </div>

                        <p>Verantwortlich für die nachfolgend dargestellte Datenerhebung und Verarbeitung die im Impressum genannte Stelle.</p>

                        <div className="offer">
                            <h2>Nutzungsdaten</h2>
                        </div>

                        <p>Um die Qualität unserer Webseiten zu verbessern, speichern wir zu statistischen Zwecken Daten über den einzelnen Zugriff auf unsere Seite. Dieser Datensatz besteht aus</p>

                        <li>der Seite, von der aus die Datei angefordert wurde, </li>
                        <li> dem Namen der Datei, </li>
                        <li> dem Datum und der Uhrzeit der Abfrage, </li>
                        <li>der übertragenen Datenmenge, </li>
                        <li>dem Zugriffsstatus (Datei übertragen, Datei nicht gefunden), </li>
                        <li> der Beschreibung des Typs des verwendeten Webbrowsers, </li>
                        <li>der IP-Adresse des anfragenden Rechners verkürzt um die letzten drei Stellen. </li>

                        <p>Die genannten Protokolldaten werden nur anonymisiert gespeichert.
</p>


                        <div className="offer">
                            <h2>Datensicherheit</h2>
                        </div>
                        <p>Um Ihre Daten vor unerwünschten Zugriffen möglichst umfassend zu schützen, treffen wir technische und organisatorische Maßnahmen.
                             Wir setzen auf unseren Seiten ein Verschlüsselungsverfahren ein. Ihre Angaben werden von Ihrem Rechner zu unserem Server und umgekehrt
                              über das Internet mittels einer TLS-Verschlüsselung übertragen. Sie erkennen dies daran, dass in der Statusleiste Ihres Browsers das
                               Schloss-Symbol geschlossen ist und die Adresszeile mit https:// beginnt.</p>


                        <div className="offer">
                            <h2>Cookies</h2>
                        </div>
                        <p>Auf unseren Webseiten nutzen wir Cookies. Cookies sind kleine Textdateien, die auf Ihrem Endgerät gespeichert und ausgelesen werden können.
                             Man unterscheidet zwischen Session-Cookies, die wieder gelöscht werden, sobald Sie ihren Browser schließen und permanenten Cookies,
                              die über die einzelne Sitzung hinaus gespeichert werden. Cookies können Daten enthalten, die eine Wiedererkennung des genutzten Geräts möglich machen.
                              Teilweise enthalten Cookies aber auch lediglich Informationen zu bestimmten Einstellungen, die nicht personenbeziehbar sind. </p>


                        <p>Wir nutzen auf unseren Webseiten Session-Cookies. Die Verarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO und in dem Interesse die Benutzerführung zu optimieren.

                        Sie können Ihren Browser so einstellen, dass er Sie über die Platzierung von Cookies informiert. So wird der Gebrauch von Cookies für Sie transparent. Sie können Cookies zudem jederzeit über die entsprechende Browsereinstellung löschen und das Setzen neuer Cookies verhindern. Bitte beachten Sie, dass unsere Webseiten dann ggf. nicht optimal angezeigt werden und einige Funktionen technisch nicht mehr zur Verfügung stehen.
                        Wir nutzen auf unseren Webseiten darüber hinaus permanente Cookies. Nähere Informationen hierzu erhalten Sie im nächsten Absatz zu Google Analytics.
</p>
                        <div className="offer">
                            <h2>Tracking Tools</h2>
                            <h2>Google Analytics
</h2>
                        </div>
                        <p>Zur bedarfsgerechten Gestaltung unserer Website erstellen wir pseudonyme Nutzungsprofile mit Hilfe von Google Analytics.
                             Google Analytics verwendet Cookies, die auf Ihrem Endgerät gespeichert und von uns ausgelesen werden können. Auf diese
                              Weise sind wir in der Lage, wiederkehrende Besucher zu erkennen und als solche zu zählen. Die Datenverarbeitung erfolgt
                               auf der Grundlage von Art. 6 Abs. 1 lit. f DSGVO bzw. § 15 Abs. 3 TMG und in dem Interesse zu erfahren, wie häufig unsere
                               Webseiten von unterschiedlichen Nutzern aufgerufen wurden.
                          Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google
                           in den USA übertragen und dort gespeichert. Da wir die IP-Anonymisierung auf dieser Webseite aktiviert haben , wird Ihre IP-Adresse
                           von Google jedoch zuvor innerhalb von Mitgliedstaaten der Europäischen Union gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse
                            an einen Server von Google in den USA (ein angemessenes Datenschutzniveau liegt nach Art. 45 Abs. 1 DSGVO durch Googles Teilnahme am
                             Privacy Shield vor) übertragen und erst dort gekürzt. Wir haben mit Google Inc. (USA) zudem einen Vertrag zur Auftragsverarbeitung
                              nach Art. 28 DSGVO geschlossen. Google alle Informationen demnach nur streng zweckgebunden nutzen, um die Nutzung unserer Website
                                     für uns auszuwerten und Reports über die Websiteaktivitäten zusammenzustellen.</p>

                        <p>Sie können der Verarbeitung jederzeit widersprechen. Bitte nutzen Sie hierfür eine der folgenden Möglichkeiten:<br />
                            1. Eine Möglichkeit, der Webanalyse durch Google Analytics zu widersprechen, besteht darin, einen Opt-Out-Cookie zu setzten, welcher Google anweist, Ihre Daten nicht für Zwecke der Webanalyse zu speichern oder zu nutzen. Bitte beachten Sie, dass bei dieser Lösung die Webanalyse nur so lange nicht erfolgen wird, wie der Opt-Out-Cookie vom Browser gespeichert wird. Wenn Sie den Opt-Out-Cookie jetzt setzen möchten, klicken Sie bitte hier.
                                    <br /> 2. Sie können die Speicherung der für die Profilbildung verwendeten Cookies auch durch eine entsprechende Einstellung Ihrer Browser-Software verhindern.<br />
                            3. Je nach dem von Ihnen verwendeten Browser, haben Sie die Möglichkeit, ein Browser-Plugin zu installieren, welches das Tracking verhindert. Dazu klicken Sie bitte hier und installieren das dort abrufbare Browser-Plugin.
                                    </p>
                        <div className="offer">
                            <h2>DoubleClick</h2>
                        </div>
                        <p>Wir setzen DoubleClick, einen Dienst der Google Inc. ("Google"), zur Verwaltung und Schaltung von Werbeanzeigen ein. Dieser Dienst speichert sog. "Cookies" (Textdateien) auf Ihrem Computer und verwendet sog. "Web Beacons" (unsichtbare Grafiken), um Ihr Nutzerverhalten auf unseren Seiten zu analysieren. Die mit Cookies und Web Beacons erzeugten Informationen, einschließlich Ihrer IP-Adresse, werden an Server von Google in den USA übertragen und dort gespeichert. Google behält sich vor, diese Informationen auch an Vertragspartner weiterzugeben. Ihre IP-Adresse wird jedoch keinesfalls mit anderen Daten über Ihre Person zusammengeführt.
                        Die Datenverarbeitung erfolgt auf der Grundlage von Art. 6 Abs. 1 lit. f DSGVO bzw. § 15 Abs. 3 TMG.
                        Sie können die Installation von Cookies in den Einstellungen Ihres Browsers blockieren und so die vorab beschriebene Datenverarbeitung verhindern. Allerdings besteht dann die Möglichkeit, dass Sie nicht sämtlicher Funktionen dieser Webseite voll nutzen können.
                        </p>
                        <div className="offer">
                            <h2> Google Tag Manager</h2>
                        </div>
                        <p>Wir verwenden zudem den Google Tag Manager, um das Nutzerverhalten anhand pseudonymisierter Nutzerdaten besser auswerten zu können. Google Tag Manager ist eine Lösung, mit der Vermarkter Website-Tags über eine Oberfläche verwalten können.
</p>

<p>Die Datenverarbeitung erfolgt auf der Grundlage von Art. 6 Abs. 1 lit. f DSGVO bzw. § 15 Abs. 3 TMG.
Dies funktioniert insoweit, als dass vom Tag Manager ein Seitenladevorgang festgestellt wird. Entsprechend der Konfiguration wird durch dieses Laden der Webseite ein Google Analytics-Tag ausgelöst. Die Daten dieses Tags werden anschließend an die Google Analytics-Server zurückgesendet, damit eine pseudonymisierte Auswertung des Nutzerverhaltens erfolgt. Dies geschieht mittels der Einbindung des Google Analytics-Codes und mithilfe der Aktivierung der oben näher beschriebenen IP-Anonymisierung. Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter http://www.google.com/analytics/terms/de.html bzw. unter http://www.google.com/intl/de/analytics/privacyoverview.html.
</p>

                       <div className="offer">
                           <h2>Kontaktformular</h2>
                       </div>
                       <p>Zur Nutzung unseres Kontaktformulars benötigen wir Ihren Namen und Ihre E-Mail-Adresse. Weitere Angaben können Sie mitteilen, müssen dies jedoch nicht. Ihre Daten verwenden wir ausschließlich für die Bearbeitung Ihrer Anfrage.</p>
<p>Ihre Daten verwenden wir ausschließlich für die Bearbeitung und Abwicklung der entsprechenden Anfrage. Rechtsgrundlage ist in den vorgenannten Fällen Art. 6 Abs. 1 lit. f DSGVO. Wir löschen Ihre Daten, sofern diese nicht mehr erforderlich sind und keine gesetzlichen Aufbewahrungsfristen entgegenstehen.
Datenübermittlung an Dritte
Wir behandeln Ihre personenbezogenen Daten vertraulich und übermitteln diese nicht an unbefugte Dritte. 
Auftragsverarbeitung
Im Rahmen des Betriebs dieser Webseiten und der damit zusammenhängenden Prozesse können uns weitere Dienstleister unterstützen (z. B. beim Hosting und der Webentwicklung). Diese Dienstleister sind uns gegenüber streng weisungsgebunden und entsprechend Artikel 28 Datenschutzgrundverordnung vertraglich verpflichtet.
</p>

<div className="offer">
    <h2>Ihre Rechte als Nutzer
</h2>
    </div>

    <p>Bei Verarbeitung Ihrer personenbezogenen Daten gewährt die DSGVO Ihnen als Webseitennutzer bestimmte Rechte:</p>


<p> 1.) Auskunftsrecht (Art. 15 DSGVO): <br/>
Sie haben das Recht eine Bestätigung darüber zu verlangen, ob sie betreffende personenbezogene Daten verarbeitet werden; ist dies der Fall, so haben Sie ein Recht auf Auskunft über diese personenbezogenen Daten und auf die in Art. 15 DSGVO im einzelnen aufgeführten Informationen. <br/>
2.) Recht auf Berichtigung und Löschung (Art. 16 und 17 DSGVO): 
Sie haben das Recht, unverzüglich die Berichtigung sie betreffender unrichtiger personenbezogener Daten und ggf. die Vervollständigung unvollständiger personenbezogener Daten zu verlangen. 
Sie haben zudem das Recht, zu verlangen, dass sie betreffende personenbezogene Daten unverzüglich gelöscht werden, sofern einer der in Art. 17 DSGVO im einzelnen aufgeführten Gründe zutrifft, z. B. wenn die Daten für die verfolgten Zwecke nicht mehr benötigt werden. <br/>
3.) Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO): 
Sie haben das Recht, die Einschränkung der Verarbeitung zu verlangen, wenn eine der in Art. 18 DSGVO aufgeführten Voraussetzungen gegeben ist, z. B. wenn Sie Widerspruch gegen die Verarbeitung eingelegt haben, für die Dauer einer etwaigen Prüfung. <br/>
4.) Recht auf Datenübertragbarkeit (Art. 20 DSGVO): 
In bestimmten Fällen, die in Art. 20 DSGVO im Einzelnen aufgeführt werden, haben Sie das Recht, die sie betreffenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten bzw. die Übermittlung dieser Daten an einen Dritten zu verlangen. <br/>
5.) Widerspruchsrecht (Art. 21 DSGVO): 
Werden Daten auf Grundlage von Art. 6 Abs. 1 lit. f erhoben (Datenverarbeitung zur Wahrung berechtigter Interessen), steht Ihnen das Recht zu, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Widerspruch einzulegen. Wir verarbeiten die personenbezogenen Daten dann nicht mehr, es sei denn, es liegen nachweisbar zwingende schutzwürdige Gründe für die Verarbeitung vor, die die Interessen, Rechte und Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>


<div className="offer">
    <h2>Beschwerderecht bei einer Aufsichtsbehörde
</h2>

<p>Sie haben gem. Art. 77 DSGVO das Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden Daten gegen datenschutzrechtliche Bestimmungen verstößt. Das Beschwerderecht kann insbesondere bei einer Aufsichtsbehörde in dem Mitgliedstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes geltend gemacht werden.</p>
</div>
                    </div>
                </Container>
                <Contact {...this.props}/>
            </>
        )
    }
}

export default DefaultLayout(dataProtection)
