import React, { Component } from 'react'
import MyButton from '../containers/myButton';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Input
} from 'reactstrap';

import ContactForm from './forms/contactForm';

class contact extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        const buttonText = "SENDEN";

        return (
            <Container className="contact">
                <Row>
                    <Col xl="5" lg="5" md="6">
                        <div className="universalTitle3">
                            <div>
                                <h3>HABEN SIE EINE FRAGE?</h3>
                                <h3>SENDEN SIE UNS</h3>
                                <h3>EINE NACHRICHT</h3>
                            </div>
                            <div>
                                <h6>Sport-Berntheusel GmbH</h6>
                                <p>E-Mail:      info@eissporthalle-bremen.de</p>
                                <p>Internet:    www.eissporthalle-bremen.de</p>
                            </div>
                        </div>
                    </Col>
                    <Col xl="6" lg="6" md="6" className="offset-xl-1 offset-lg-1">
                        <ContactForm onSubmit={(data) => {
                            this.props.core.contact(data, () => {
                                this.setState({
                                    _done: true
                                })
                            })
                        }}></ContactForm>
                        {this.state._done ?
                            <div class="alert alert-success mt-3 mb-5" role="alert">
                                Die Nachricht wurde erfolgreich gesendet!
                     </div> : null}
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default contact
