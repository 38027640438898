import React, { Component } from 'react'
import Navbar from '../containers/navbar';
import HeaderBackground2 from '../images/header2BG.png';
import { Link } from 'react-router-dom';
import GoogleMap from '../containers/map.js';
import Contact from '../containers/contact';
import Footer from '../containers/footer.js';
import AboutPicture from '../images/aboutPicture.png';
import DefaultLayout from '../containers/defaultLayout';
import MobileBackground from '../images/mobileBG.png';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Input,
    NavItem
} from 'reactstrap';
class about extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        this.state = {
            home: {
                section1: {
                    title: '',
                    subtitle: ''
                },
                aboutus: {
                    image: null,
                    title: '',
                    content: ''
                }
            }
        }
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.props.core.fetchHome((data) => {
            this.setState({
                home: data
            })
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    render() {

        return (
            <>
                <div className="header2">
                    {this.state.width > 991 ?
                        <img src={HeaderBackground2} className="headerBG" /> : <img src={MobileBackground} className="headerBG"></img>}
                </div>
                <Container className="aboutPage">
                    <Row noGutters className="d-block d-md-flex d-lg-flex justify-content-between universalTextPage align-items-center">
                        <div>
                            <h1>Über uns</h1>
                        </div>
                        <div className="bredkramb">
                            <NavItem>
                                <Link to="/"> Startseite</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/"> Über uns</Link>
                            </NavItem>
                        </div>
                    </Row>
                    <Row>
                        <Col xl="12" lg="12" md="12" xs="12">
                            <img src={this.state.home.aboutus.image} className="img-fluid" />
                        </Col>
                        <Col xl="12" lg="12" md="12" xs="12">
                            <p><span>{this.state.home.aboutus.title}</span></p>
                            <div dangerouslySetInnerHTML={{ __html: this.state.home.aboutus.content }}></div>
                        </Col>
                    </Row>
                </Container>


                <GoogleMap {...this.props} />
                <Contact {...this.props} />
            </>
        )
    }
}

export default DefaultLayout(about)
