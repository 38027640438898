import React, { Component } from 'react'
import Navbar from '../containers/navbar';
import HeaderBackground2 from '../images/header2BG.png';
import { Link } from 'react-router-dom';
import GoogleMap from '../containers/map.js';
import Contact from '../containers/contact';
import Footer from '../containers/footer.js';
import EventDetailPicture from '../images/eventDetailPicture.png';
import EventsPicture1 from '../images/events1.png';
import EventsPicture2 from '../images/events2.png';
import EventsPicture3 from '../images/events3.png';
import CurrentInformation from '../containers/currentInformation';
import DefaultLayout from '../containers/defaultLayout';
import MobileBackground from '../images/mobileBG.png';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Input,
    NavItem
} from 'reactstrap';
class eventsDetail extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.state = {
            data: {},
            informations: [],
            events: []
        }
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        this.props.core.fetchLastInformations((data) => {
            this.setState({
                informations: data
            })
        })

        this.props.core.fetchEventsItem(this.props[0].match.params.id, (data) => {

            this.setState({
                data: data
            })
        })

        this.props.core.fetchLastEvents((data) => {
            this.setState({
                events: data
            })
        })

    }



    componentDidUpdate(prevProps) {
        if (prevProps[0].match.params.id !== this.props[0].match.params.id) {
            this.props.core.fetchEventsItem(this.props[0].match.params.id, (data) => {

                this.setState({
                    data: data
                })
            })

        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    render() {

        return (
            <>
                <div className="header2">
                    {this.state.width > 991 ?
                        <img src={HeaderBackground2} className="headerBG" /> : <img src={MobileBackground} className="headerBG"></img>}
                </div>
                <Container className="eventDetailPage">
                    <Row noGutters className="d-block d-md-flex d-lg-flex justify-content-between universalTextPage align-items-center">
                        <div>
                            <h1>Veranstaltungen</h1>
                        </div>
                        <div className="bredkramb">
                            <NavItem>
                                <Link to="/">Startseite</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/">Veranstaltungen</Link>
                            </NavItem>
                        </div>
                    </Row>
                    <Row>
                        <Col xl="8" lg="8" md="8" xs="12" className="middle">
                            <img src={this.state.data.image} className="img-fluid"></img>
                            <h4>{this.state.data.title}</h4>
                            <p className="orangeParagraf">{this.state.data.date}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{this.state.data.tm}</p>
                            <div dangerouslySetInnerHTML={{ __html: this.state.data.content }}></div>
                        </Col>
                        <Col xl="4" lg="4" md="4" xs="12">
                            <h3>AKTUELLE UND KÜNFTIGE VERANSTALTUNGEN</h3>
                            {
                                this.state.events.map((item, idx) => {
                                    return (
                                        <Link to={`/veranstaltungen/${item.title}/${item._id}`} className="text-decoration-none" key={idx}>
                                            <div className="futureEventsBox">
                                                <div className="futureEventsBoxImage">
                                                    <img src={item.image} className="img-fluid" />
                                                </div>
                                                <div className="futureEventsInformationText">
                                                    <h4>{item.title}</h4>
                                                    <div className="d-flex justify-content-between">
                                                        <p>{item.date}</p>
                                                        <p>{item.tm}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })
                            }

                        </Col>

                    </Row>

                </Container>
                <div className="noButtonWrapper">
                    <CurrentInformation items={this.state.informations} />
                </div>
            </>
        )
    }
}

export default DefaultLayout(eventsDetail);
