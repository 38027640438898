import React, { Component } from 'react'
import HeaderBackground2 from '../images/header2BG.png';
import { Link } from 'react-router-dom';
import DefaultLayout from '../containers/defaultLayout';
import MobileBackground from '../images/mobileBG.png';


import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Input,
    NavItem
} from 'reactstrap';

class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0, items: [] };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        this.props.core.faq((data) => {
            this.setState({
                items: data
            })
        })
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {

        return (
            <>
                <div className="header2">
                    {this.state.width > 991 ? <img src={HeaderBackground2} className="headerBG" /> : <img src={MobileBackground} className="headerBG"></img>}
                </div>
                <Container className="lostAndFoundPage">
                    <Row noGutters className="d-block d-md-flex d-lg-flex justify-content-between universalTextPage align-items-center">
                        <div>
                            <h1>FAQ</h1>
                        </div>
                        <div className="bredkramb">
                            <NavItem>
                                <Link to="/">Startseite</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/">FAQ</Link>
                            </NavItem>
                        </div>
                    </Row>
                    <Row>
                        {
                            this.state.items.map((item, idx) => {
                                return (
                                    <Col xl = "12" className = "spacer" key={idx}>
                                    <h3>{item.question}</h3>
                                    <p>{item.answer}</p>
                                </Col>
        
                                )
                            })
                        }
                    </Row>
                </Container>
            </>
        )
    }
}

export default DefaultLayout(Faq);
