import React, { Component } from 'react';
import Navbar from '../containers/navbar';
import HeaderBackground from '../images/headerBG.png';
import OpeningHours from '../containers/openingHours';
import CurrentInformation from '../containers/currentInformation';
import FutureEvents from '../containers/futureEvents';
import ServicePicture1 from '../images/service1.png';
import ServicePicture2 from '../images/service2.png';
import ServicePicture3 from '../images/service3.png';
import Isvg from 'react-inlinesvg'
import LeftIcon from '../images/svg/left-icon.svg';
import { Link } from 'react-router-dom';
import GoogleMap from '../containers/map.js';
import Contact from '../containers/contact';
import logo from '../images/svg/logo.svg';
import Footer from '../containers/footer.js';
import ReactDOM from 'react-dom';
import { createRef } from 'react';
import MyButton from '../containers/myButton';
import DefaultLayout from '../containers/defaultLayout';
import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Input,
    NavItem
} from 'reactstrap';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            informations: [],
            events: [],
            home: {
                section1: {
                    title: '',
                    subtitle: ''
                },
                aboutus: {
                    image: null,
                    title: '',
                    content: ''
                } ,
                section2: {
                    title: '',
                    subtitle: ''
                }, 
                
                section3: {
                    title: '',
                    subtitle: ''
                }, 
                
                section4: {
                    title: '',
                    subtitle: ''
                }, 
            }

        }
    }


    componentDidMount() {
        this.props.core.fetchLastInformations((data) => {
            this.setState({
                informations: data
            })
        })
        this.props.core.fetchEvents((data) => {
            let events = [];
            for(let i=0;i<data.length;i++){
                let dateText = data[i].date;
                if (dateText[dateText.length -1 ] == '.'){
                    dateText = dateText.substring(0, dateText.length - 1);

                }

                let brokenDate = dateText.split('.');

                let month = brokenDate[1];
                let day = brokenDate[0];
                let year = brokenDate[brokenDate.length -1];
                if (year.length == 2){
                    year = '20'+year;
                }


                let date = new Date(year, month, day, 0, 0 , 0, 0);
                
                data[i].ts = date.getTime() / 1000;
                events.push(data[i]);

            }

            events.sort((a, b) => (a.ts > b.ts) ? 1 : -1)
            events = events.splice(0, 3);


            this.setState({
                events: events
            })
        })
        this.props.core.fetchHome((data) => {
            this.setState({
                home: data
            })
        })


    }

    render() {
        const ref = createRef();

        const handleClick = () =>
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        const buttonText = "MEHR INFO";
        const buttonText2 = "KONTAKT";

        return (
            <div>
                <div className="header">
                    <img src={HeaderBackground} className="headerBG" />
                    <Container className="headerText">
                        <Row>
                            <Col xl="6" lg="6" md="6" xs="12" dangerouslySetInnerHTML={{__html: this.state.home.section1.title}}></Col>
                        </Row>
                        <Row>
                            <Col xl="5" lg="5" md="7" dangerouslySetInnerHTML={{__html: this.state.home.section1.subtitle}}></Col>
                        </Row>
                        <Row>
                            <Col xl="5" className="buttons">
                                <Link to="/uber-uns"><MyButton buttonText={buttonText}></MyButton></Link>
                                <MyButton buttonText2={buttonText2} handleClick={handleClick}></MyButton>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <OpeningHours core={this.props.core}/>

                <div className="background">
                    <CurrentInformation items={this.state.informations} />
                    <FutureEvents items={this.state.events}/>
                </div>

                <div className="services">
                    <Container>
                        <Row noGutters>
                            <Row className="contentContainer">
                                <Col xl={{ size: 6, order: 0 }} lg={{ size: 6, order: 0 }} md={{ size: 6, order: 0 }} xs={{ size: 12, order: 1 }}>
                                    <img src={this.state.home.section2.image} className="img-fluid" />
                                </Col>
                                <Col xl={{ size: 6, order: 1 }} lg={{ size: 6, order: 1 }} md={{ size: 6, order: 1 }} xs={{ size: 12, order: 0 }}>
                                    <div className="universalTitle2">
                                        <div>
                                            <h3>{this.state.home.section2.title}</h3>
                                        </div>
                                    </div>
                                    <div className="text">
                                        <h6>{this.state.home.section2.subtitle}</h6>
<div dangerouslySetInnerHTML={{__html: this.state.home.section2.content}}></div>

<Link to="/eintrittspreise" className="blueLink">Mehr Info<Isvg src={LeftIcon} /></Link>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="contentContainer">
                                <Col xl={{ size: 6, order: 0 }} lg={{ size: 6, order: 0 }} md={{ size: 6, order: 0 }} xs={{ size: 12, order: 0 }}>
                                    <div className="universalTitle2">
                                        <div>
                                            <h3>{this.state.home.section3.title}</h3>
                                        </div>
                                    </div>
                                    <div className="text">
                                        <h6>{this.state.home.section3.subtitle}</h6>
                                        <div dangerouslySetInnerHTML={{__html: this.state.home.section3.content}}></div>
                                        <Link to="/schulklassen" className="blueLink">Mehr Info<Isvg src={LeftIcon} /></Link>
                                    </div>
                                </Col>
                                <Col xl={{ size: 6, order: 1 }} lg={{ size: 6, order: 1 }} md={{ size: 6, order: 1 }} xs={{ size: 12, order: 1 }}>
                                    <img src={this.state.home.section3.image} className="img-fluid" />
                                </Col>
                            </Row>
                            <Row className="contentContainer">
                                <Col xl={{ size: 6, order: 0 }} lg={{ size: 6, order: 0 }} md={{ size: 6, order: 0 }} xs={{ size: 12, order: 1 }}>
                                    <img src={this.state.home.section4.image} className="img-fluid" />
                                </Col>
                                <Col xl={{ size: 6, order: 1 }} lg={{ size: 6, order: 1 }} md={{ size: 6, order: 1 }} xs={{ size: 12, order: 0 }}>
                                    <div className="universalTitle2">
                                        <div>
                                            <h3>{this.state.home.section4.title}</h3>
                                        </div>
                                    </div>
                                    <div className="text">
                                        <h6>{this.state.home.section4.subtitle}</h6>
                                        <div dangerouslySetInnerHTML={{__html: this.state.home.section4.content}}></div>
                                        <Link to="/kindergeburtstag" className="blueLink">Mehr Info<Isvg src={LeftIcon} /></Link>
                                    </div>
                                </Col>
                            </Row>
                        </Row>
                    </Container>
                    <GoogleMap {...this.props} />
                    <div ref={ref}>
                        <Contact {...this.props}></Contact>
                    </div>
                </div>
            </div>
        )
    }

}

export default DefaultLayout(Home);