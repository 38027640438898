import React, { Component } from 'react';
import LeftIcon from '../images/svg/left-icon.svg';
import Isvg from 'react-inlinesvg'


export default class AllInfoButton extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            x: 0,
            y: 0,
            isGradientVisible: false,
            hovered: false,
            blue: '#599CF3'
        };
    }


    _onMouseOver(e) {
        if (!this.state.hovered) {
            this.setState({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY, isGradientVisible: true, hovered: true });
        }
    }


    render() {
        const { x, y } = this.state;

        var buttonStyle = {
            position: 'absolute',
            top: `${y}px`,
            left: `${x}px`,
            borderRadius: '100%',
            zIndex: '1',
            opacity: '1',
            outline: 'none'
        }
        return (
            <>
                <button onClick={this.props.handleClick} className={this.state.hovered ? "myButton4" : "myButton3"} onMouseMove={this._onMouseOver.bind(this)} onMouseLeave={() => { this.setState({ isGradientVisible: false, hovered: false }) }}>{this.state.isGradientVisible ? <div style={buttonStyle} className='animacija'></div> : null}<span>{this.props.buttonText}<Isvg src={LeftIcon}/></span></button>
            </>

        )
    }
}