import React, { Component } from 'react'
import Navbar from '../containers/navbar';
import HeaderBackground2 from '../images/header2BG.png';
import { Link } from 'react-router-dom';
import GoogleMap from '../containers/map.js';
import Contact from '../containers/contact';
import Footer from '../containers/footer.js';
import CuurentInfoDetailPicture from '../images/infoDetailPicture.png';
import currentInformationPicture1 from '../images/aktuelne-informacije1.png';
import currentInformationPicture2 from '../images/aktuelne-informacije2.png';
import currentInformationPicture3 from '../images/aktuelne-informacije3.png';
import currentInformationPicture4 from '../images/aktuelne-informacije4.png';
import Isvg from 'react-inlinesvg';
import LeftIcon from '../images/svg/left-icon.svg';
import FutureEvents from '../containers/futureEvents';
import MyButton from '../containers/myButton';
import DefaultLayout from '../containers/defaultLayout';
import MobileBackground from '../images/mobileBG.png';


import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Input,
    NavItem
} from 'reactstrap';
import LostAndFoundForm from '../containers/forms/lostAndFoundForm';

class lostAndFound extends Component {
    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0, sex: 'Frau' };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {
        const buttonText = "SENDEN";

        return (
            <>
                <div className="header2">
                    {this.state.width > 991 ? <img src={HeaderBackground2} className="headerBG" /> : <img src={MobileBackground} className="headerBG"></img>}
                </div>
                <Container className="lostAndFoundPage">
                    <Row noGutters className="d-block d-md-flex d-lg-flex justify-content-between universalTextPage align-items-center">
                        <div>
                            <h1>Lost & found</h1>
                        </div>
                        <div className="bredkramb">
                            <NavItem>
                                <Link to="/">Startseite</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/">Lost & found</Link>
                            </NavItem>
                        </div>
                    </Row>
                    <Row noGutters>
                        <div className="text">
                            <h6>FUNDBÜRO</h6>
                            <p>Sie haben bei Ihrem Besuch etwas im Paradice verloren oder vergessen? Wir haben ein kleines Fundbüro. Bitte füllen Sie das Formular aus und beschreiben den Gegenstand so gut wie möglich. Bitte auch, wann und wo Sie ihn verloren/ vergessen haben.</p>
                        </div>
                    </Row>
                    <Row>
                        <Col lg="6" md="6" xs="12">
                            <p><span>Anrede:</span></p>
                            <div className="radioButtonWrapper">
                                <li onClick={() => this.setState({ sex: 'Frau' })}><Input type="radio" name="sex" checked={this.state.sex == 'Frau' ? true : false} />Frau</li>
                                <li onClick={() => this.setState({ sex: 'Herr' })}><Input type="radio" name="sex" checked={this.state.sex == 'Herr' ? true : false} />Herr</li>
                            </div>
                            <LostAndFoundForm onSubmit={(data) => {
                                let dat = data;
                                dat.sex = this.state.sex;
                                this.props.core.lostFound(dat, () => {
                                    this.setState({
                                        _done: true
                                    })
                                })
                            }}></LostAndFoundForm>
                            {this.state._done ?
                                <div class="alert alert-success mt-3 mb-5" role="alert">
                                    Die Nachricht wurde erfolgreich gesendet!
                     </div> : null}
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default DefaultLayout(lostAndFound);
