import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import de from 'date-fns/locale/de';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import TimePicker from 'react-times';
// use material theme
import 'react-times/css/material/default.css';
// or you can use classic theme
import 'react-times/css/classic/default.css';

import {
    Input,
} from 'reactstrap';

registerLocale('de', de)
setDefaultLocale('de');

class SelectComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Input className={this.props.error ? 'required' : this.props.value ? "black " : null} type={this.props.type} onChange={this.props.onChange}>
                {this.props.children}
            </Input>

        )
    }
}

class FormComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Input className={this.props.error ? 'required' : this.props.value ? "black " : null} type={this.props.type} onChange={this.props.onChange} placeholder={this.props.placeholder}>
                {this.props.children}
            </Input>

        )
    }
}

class DateComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        if (!this.props.value) {

            this.props.onChange(new Date());
        }
    }

    render() {
        return (
            <DatePicker
                selected={this.props.value}
                onChange={this.props.onChange}
                dateFormat="P"
                className={this.props.error ? 'required' : null}

            />
        )
    }
}

class TimeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            time: '10:00'
        }
    }
    componentDidMount() {
        if (!this.props.value) {

            this.props.onChange(this.setState({ time: this.state.time }));
        }
    }

    onTimeChange(options) {
        // do something
        //console.log(options);
        this.props.onChange(options.hour + ":" + options.minute);
    }

    onFocusChange(focusStatue) {
        // do something
    }


    render() {
        return (

            <TimePicker
                onFocusChange={this.onFocusChange.bind(this)}
                onTimeChange={this.onTimeChange.bind(this)}
                withoutIcon
                time={this.props.value}
            />



        )

        {/*
                        <Input className={this.props.error ? 'required' : this.props.value ? "black " : null} type={"text"} onChange={(e) => {
                console.log(e.target.value.length);
                if (e.target.value.length > 5){
                    this.props.onChange(this.props.value);

                    return;
                }
                if (e.target.value.length == 2){
                    e.target.value += ':';
                }
                this.props.onChange(e.target.value);
            }} placeholder={this.props.placeholder}>
                {this.props.children}
            </Input>

            
            
            <TimePicker
                onChange={(val)=> {
                    //console.log(val.length);
                    //if (val.length > 5){
                    //    return;
                   // }
                    this.props.onChange(val);
                }}
                value={this.props.value}
                className={this.props.error ? 'required' : null}
                disableClock
                format="HH:mm"
            />
            */}
    }
}

class CheckComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    render() {
        return (
            <div onClick={() => this.props.onChange(!this.props.value)} className={this.props.error ? 'input-wrap' : 'input-wrap'}>
                <div className={this.props.error ? "checkbox required" : "checkbox" && this.props.value ? "checkbox checked" : "checkbox"} ></div>

                <p>Ja&nbsp;&nbsp;&nbsp;&nbsp;</p>

                <div className={this.props.error ? "checkbox required" : "checkbox" && !this.props.value ? "checkbox checked" : "checkbox"} ></div>

                <p>Nein</p>
            </div>)
    }

}


export const renderField = ({
    input,
    placeholder,
    type,
    children,
    meta: { touched, error },
}) => (

        <FormComponent
            placeholder={placeholder}
            type={type}
            errorText={touched && error}
            error={touched && error}
            children={children}
            {...input}
        />
    )

export const renderSelectField = ({
    input,
    placeholder,
    type,
    children,
    meta: { touched, error },
}) => (

        <SelectComponent
            placeholder={placeholder}
            type={type}
            errorText={touched && error}
            error={touched && error}
            children={children}
            {...input}
        />
    )

export const renderCheckField = ({
    input,
    placeholder,
    type,
    children,
    meta: { touched, error },
}) => (

        <CheckComponent
            placeholder={placeholder}
            type={type}
            errorText={touched && error}
            error={touched && error}
            children={children}
            {...input}
        />
    )


export const renderDateField = ({
    input,
    meta: { touched, error },
}) => (

        <DateComponent
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )


export const renderTimeField = ({
    input,
    meta: { touched, error },
}) => (

        <TimeComponent
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )