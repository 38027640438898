import React, { Component } from 'react'
import Navbar from '../containers/navbar';
import HeaderBackground2 from '../images/header2BG.png';
import { Link } from 'react-router-dom';
import GoogleMap from '../containers/map.js';
import Contact from '../containers/contact';
import Footer from '../containers/footer.js';
import shopSlika1 from '../images/shopSlika1.jpg';
import shopSlika2 from '../images/shopSlika2.jpg';
import DefaultLayout from '../containers/defaultLayout';
import MobileBackground from '../images/mobileBG.png';


import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Input,
    NavItem
} from 'reactstrap';

class iceShop extends Component {
    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.props.core.fetchShop((data) => {
            this.setState(data);
        })
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    render() {

        return (
            <>
                <div className="header2">
                    {this.state.width > 991 ? <img src={HeaderBackground2} className="headerBG" /> : <img src={MobileBackground} className="headerBG"></img>}
                </div>
                <Container className="lostAndFoundPage">
                    <Row noGutters className="d-block d-md-flex d-lg-flex justify-content-between universalTextPage align-items-center">
                        <div>
                            <h1>Eissport-shop</h1>
                        </div>
                        <div className="bredkramb">
                            <NavItem>
                                <Link to="/">Startseite</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/">Eissport-shop</Link>
                            </NavItem>
                        </div>
                    </Row>
                    <Row noGutters>
                        <div className="text">
                            <h6>UNSER PARTNER IM PARADICE</h6>
                            <div dangerouslySetInnerHTML={{__html: this.state.content}} >
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <Col xl="2" lg="2" md="3" xs="3">
                            <div className="text">
                                <p><span>Montag:</span></p>
                                <p><span>Dienstag:</span></p>
                                <p><span>Mittwoch:</span></p>
                                <p><span>Donnerstag:</span></p>
                                <p><span>Freitag:</span></p>
                                <p><span>Samstag:</span></p>
                                <p><span>Sonntag:</span></p>
                            </div>
                        </Col>
                        <Col xl="4" lg="4" md="5" xs="9">
                            <div className="text">
                                <p>{this.state.workingTime && this.state.workingTime[0]}</p>
                                <p>{this.state.workingTime && this.state.workingTime[1]}</p>
                                <p>{this.state.workingTime && this.state.workingTime[2]}</p>
                                <p>{this.state.workingTime && this.state.workingTime[3]}</p>
                                <p>{this.state.workingTime && this.state.workingTime[4]}</p>
                                <p>{this.state.workingTime && this.state.workingTime[5]}</p>
                                <p>{this.state.workingTime && this.state.workingTime[6]}</p>
                            </div>
                        </Col>
                        <Col xl="12">
                            <div className="text">
                                <p>Oder nach Vereinbarung</p>
                                <p>Mail: bremen@hockeyshop-forster.de</p>
                            </div>
                            <div className="text">
                                <p>Online Angebote: <a href="https://www.ebay.de/usr/hockeyshop-forster-bremen">www.hockeyshop-bremen.de</a></p>
                            </div>
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <img src={this.state.offer1} className="img-fluid" />
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <img src={this.state.offer2} className="img-fluid" />
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default DefaultLayout(iceShop);
