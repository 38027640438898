import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import Home from './views/home.js';
import About from './views/about.js';
import Events from './views/events.js';
import EventsDetail from './views/eventsDetail.js';
import BirthdayPage from './views/birthday';
import CurrentInformationPage from './views/currentInformation';
import CurrentInformationDetail from './views/currentInformationDetail';
import PriceList from './views/priceList';
import ScrollIntoView from "./scrollIntoView";
import Imprint from './views/imprint.js';
import DataProtection from './views/dataProtection.js';
import Fond from './views/fond.js';
import LostAndFound from './views/lostAndFound';
import IceShop from './views/iceShop';
import Jobs from './views/jobs';
import FAQ from './views/faq';
import Anmietung from './views/anmietung';
import Offnungszeiten from './views/offnungszeiten.js';
import './App.css';
import GoogleMap from './googleMapScript';
import { Core } from './core';
import Team from './views/team.js';
import Eisdiscos from './views/eisdiscos.js';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.callback = this.callback.bind(this);
    this.core = new Core();
    this.core.fetchWorkingTime(() => {})


    window.googleMapsCallback = this.callback;
    this.state = {
      pages: {
        'Kindergeburtstag': {},
        'Schulklassen': {},
        'Anmietung': {}
      }
    }
  }

  callback() {
    this.setState({ mapinit: true });
  }

  componentDidMount(){
    this.core.fetchPages((data) => {
      console.log(data);
        this.setState({
          pages: data
        })
    })
  }

  render() {
    return (
      <div>
        <GoogleMap API_KEY="AIzaSyAaSt58UDZVRmMYe52a3cCPfJaoCaHUJqY" />
        <Router>
          <ScrollIntoView>
            <Switch>
              <Route exact path="/" render={(...renderProps) => (<Home {...renderProps} {...this.state} core={this.core}></Home>)} />
              <Route exact path="/uber-uns" render={(...renderProps) => (<About {...renderProps} {...this.state} core={this.core}></About>)} />
              <Route exact path="/veranstaltungen" render={(...renderProps) => (<Events {...renderProps} {...this.state} core={this.core}></Events>)} />
              <Route exact path="/veranstaltungen/:title/:id" render={(...renderProps) => (<EventsDetail {...renderProps} {...this.state} core={this.core}></EventsDetail>)} />
              <Route exact path="/kindergeburtstag" render={(...renderProps) => (<BirthdayPage {...renderProps} {...this.state} core={this.core}></BirthdayPage>)} />
              <Route exact path="/aktuelle-informationen" render={(...renderProps) => (<CurrentInformationPage {...renderProps} {...this.state} core={this.core}></CurrentInformationPage>)} />
              <Route exact path="/aktuelle-informationen/:title/:id" render={(...renderProps) => (<CurrentInformationDetail {...renderProps} {...this.state} core={this.core}></CurrentInformationDetail>)} />
              <Route exact path="/eintrittspreise" render={(...renderProps) => (<PriceList {...renderProps} {...this.state} core={this.core}></PriceList>)} />

              <Route exact path="/schulklassen" render={(...renderProps) => (<Fond {...renderProps} {...this.state} core={this.core}/>)} />
              <Route exact path="/datenschutzerklärung" render={(...renderProps) => (<DataProtection core={this.core}/>)} />
              <Route exact path="/Impressum" render={(...renderProps) => (<Imprint  {...renderProps} {...this.state} core={this.core} />)} />
              <Route exact path="/lost-and-found" render={(...renderProps) => (<LostAndFound  {...renderProps} {...this.state} core={this.core} />)} />
              <Route exact path="/eissport-shop" render={(...renderProps) => (<IceShop  {...renderProps} {...this.state} core={this.core} />)} />
              <Route exact path="/jobs" render={(...renderProps) => (<Jobs  {...renderProps} {...this.state} core={this.core} />)} />
              <Route exact path="/faq" render={(...renderProps) => (<FAQ  {...renderProps} {...this.state} core={this.core} />)} />
              <Route exact path="/anmietung" render={(...renderProps) => (<Anmietung  {...renderProps} {...this.state} core={this.core} />)} />
              <Route exact path="/offnungszeiten" render={(...renderProps) => (<Offnungszeiten  {...renderProps} {...this.state} core={this.core} />)} />
              <Route exact path="/team" render={(...renderProps) => (<Team  {...renderProps} {...this.state} core={this.core} />)} />

            </Switch>
          </ScrollIntoView>
        </Router>
      </div>
    )
  }
}

export default App;
