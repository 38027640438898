import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg'
import LeftIcon from '../images/svg/left-icon.svg';
import EventsPicture1 from '../images/events1.png';
import EventsPicture2 from '../images/events2.png';
import EventsPicture3 from '../images/events3.png';
import AllInfoButton from '../containers/allInfoButton';

import {
    Container,
    Row,
    Col,
    Button
} from 'reactstrap';

class futureEvents extends Component {
    render() {
        const buttonText = "Alle Veranstaltungen";

        return (
            <Container>
                <Row className="d-flex justify-content-center">
                    <div className="universalTitle">
                        <h3>AKTUELLE UND KÜNFTIGE VERANSTALTUNGEN</h3>
                    </div>
                </Row>
                <Row className="futureEvents">
                {
                        this.props.items.map((item, idx) => {
                            return (
                                <Col xl="4" lg="4" md="6" xs="6" key={idx}>
                                <Link to={`/veranstaltungen/${item.title}/${item._id}`} className="text-decoration-none">
                                    <div className="futureEventsBox">
                                        <div className="futureEventsBoxImage">
                                            <img src={item.image} className="img-fluid" />
                                        </div>
                                        <div className="futureEventsInformationText">
                                            <h4>{item.title}</h4>
                                            <div className="d-flex justify-content-between">
                                                <p>{item.date}</p>
                                                <p>{item.tm}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                
                            )
                        })
                    }

                    <Link to="/veranstaltungen" className="allInformationButton"><AllInfoButton buttonText={buttonText}>Alle Veranstaltungen <Isvg src={LeftIcon} /></AllInfoButton></Link>

                </Row>
            </Container>
        )
    }
}

export default futureEvents
