import React, { Component } from 'react'
import Navbar from '../containers/navbar';
import HeaderBackground2 from '../images/header2BG.png';
import { Link } from 'react-router-dom';
import GoogleMap from '../containers/map.js';
import Contact from '../containers/contact';
import Footer from '../containers/footer.js';
import AboutPicture from '../images/aboutPicture.png';
import DefaultLayout from '../containers/defaultLayout';
import MobileBackground from '../images/mobileBG.png';
import ServicePicture1 from '../images/disco.png';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Input,
    NavItem
} from 'reactstrap';


const data = [
    ['21.09.2019', 'Samstag', '20:00 – 22:30', 'Pre-Opening Disco', 'DJ Conceited'],
    ['05.10.2019', 'Samstag', '20:00 – 22:30', 'Eröffnungs-Disco', 'DJ Coralik'],
    ['19.10.2019', 'Samstag', '20:00 – 22:30', 'Freimarktsdisco Mixed Music und Party', 'Jonas Weirich'],
    ['02.11.2019', 'Samstag', '19:00 – 22:30', 'Halloween Disco ', 'DJ Conceited'],
    ['16.11.2019', 'Samstag', '19:00 – 22:30', 'Black Music', 'DJ Manilla'],

    ['30.11.2019', 'Samstag', '20:00 – 00:00', '4.Pink Ice Disco', 'Wird noch benannt'],
    ['14.12.2019', 'Samstag', '19:00 – 22:30', 'Best of 90´-Now', 'DJ Kremin'],
    ['21.12.2019', 'Samstag', '19:00 – 22:30', 'X-Mas', 'DJ Dirk Heitmann'],
    ['28.12.2019', 'Samstag', '19:00 – 22:30', 'Top-Act altern.:Flirt-Party', 'Wird noch benannt'],
    ['04.01.2020', 'Samstag', '19:00 – 22:30', 'New Year', 'DJ Dirk Heitmann'],
    ['18.01.2020', 'Samstag', '19:00 – 00:00', 'Latin-Night', 'Wird noch benannt'],
    ['01.02.2020', 'Samstag', '19:00 – 22:30', 'Black Music', 'DJ Kremin'],
    ['15.02.2020', 'Samstag', '19:00 – 22:30', 'Schwarzlicht-Disco', 'DJ Manilla'],
    ['29.02.2020', 'Samstag', '19:00 – 22:30', 'Birthday Special', 'Jonas Weirich'],
    ['07.03.2020', 'Samstag', '19:00 – 22:30', 'Black Music', 'DJ Kremin'],
    ['21.03.2020', 'Samstag', '19:00 – 22:30', 'Flirt-Party', 'Jonas Weirich'],
    ['28.03.2020', 'Samstag', '19:00 – 22:30', 'Abschlussdisco', 'Chris Packer'],

]

class eisdiscos extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        this.state = { width: 0, height: 0, priceList: [] };
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.props.core.fetchPriceList((data) => {
            this.setState({ priceList: data })
        })
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    render() {
        return (
            <>
                <div className="header2">
                    {this.state.width > 991 ? <img src={HeaderBackground2} className="headerBG" /> : <img src={MobileBackground} className="headerBG"></img>}
                </div>
                <Container className="priceListPage">
                    <Row noGutters className="d-block d-md-flex d-lg-flex justify-content-between universalTextPage align-items-center">
                        <div>
                            <h1>Eisdiscos</h1>
                        </div>
                        <div className="bredkramb">
                            <NavItem>
                                <Link to="/"> Startseite</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/"> Eisdiscos</Link>
                            </NavItem>
                        </div>
                    </Row>
                    <Row className="d-flex justify-content-center mb-4">
                        <Col xl="6">
                            <img src={ServicePicture1} className="img-fluid" />
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center">
                        <div className="universalTitle">
                            <h3>Eisdiscos 2019 / 2020</h3>
                        </div>
                    </Row>


                    {
                        data.map((item, idx) => {
                            return (
                                <Row noGutters className="silverRow">
                                    <Col xl="2" lg="2" md="2" xs="2">
                                        <p>{item[0]}</p>
                                    </Col>
                                    <Col xl="2" lg="2" md="2" xs="2">
                                        <p>{item[1]}</p>

                                    </Col>
                                    <Col xl="2" lg="2" md="2" xs="2">
                                        <p>{item[2]}</p>
                                    </Col>

                                    <Col xl="3" lg="3" md="3" xs="3">
                                        <p>{item[3]}</p>
                                    </Col>
                                    <Col xl="3" lg="3" md="3" xs="3">
                                        <p>{item[4]}</p>
                                    </Col>


                                </Row>

                            )
                        })
                    }


                    <Row noGutters className="silverRow">
                        <Col xl="2" lg="2" md="2" xs="2">
                            <p>21.09.2019</p>
                        </Col>
                        <Col xl="2" lg="2" md="2" xs="2">
                            <p>Samstag</p>

                        </Col>
                        <Col xl="2" lg="2" md="2" xs="2">
                            <p>20:00 – 22:30</p>
                        </Col>

                        <Col xl="3" lg="3" md="3" xs="3">
                            <p>Pre-Opening Disco</p>
                        </Col>
                        <Col xl="3" lg="3" md="3" xs="3">
                            <p>DJ Conceited</p>
                        </Col>


                    </Row>
                </Container>


                <GoogleMap {...this.props} />
                <Contact {...this.props} />
            </>
        )
    }
}

export default DefaultLayout(eisdiscos)
