import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Navbar from '../containers/navbar.js';
import Footer from '../containers/footer.js';
import arrowDown from '../images/svg/arrowDown.svg';
import HeaderBackground2 from '../images/header2BG.png';
import MyButton from '../containers/myButton';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import DefaultLayout from '../containers/defaultLayout';
import MobileBackground from '../images/mobileBG.png';
import Cestitka from '../images/cestitka.png';
import ServicePicture2 from '../images/service3.png';

import {
    Container, Row, Col, Button, FormGroup,
    Input, NavItem
} from 'reactstrap';


import BirthdayForm from '../containers/forms/birthdayForm';

import 'moment/locale/de'  // without this line it didn't work

const moment = require('moment')
moment.locale('de')


export class birthday extends Component {
    constructor(props) {
        super(props);
        this.generateImage = this.generateImage.bind(this);

        this.state = {
            check: false,
            startDate: new Date(),
            width: 0, height: 0, value: 1
        }
        this.handleChange = this.handleChange.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.asd = this.asd.bind(this);
    }
    componentDidMount() {

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);


    }


    generateImage(name, date) {
        const canvas = this.refs.canvas;
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.src = Cestitka;
        img.width = 1000;
        img.height = 1400;

        let location = 'Waller Heestr. 293a,\n28219 Bremen';


        img.onload = () => {
            ctx.drawImage(img, 0, 0, 700, 980)
            ctx.font = "bold 48px Roboto"
            ctx.fillStyle = "#fff";  //<======= here
            ctx.textAlign = "center";
            ctx.fillText(name, 350, 300);

            ctx.font = "bold 60px Roboto"
            ctx.fillStyle = "#ED5822";  //<======= here
            ctx.textAlign = "center";
            ctx.fillText(moment(date).format('DD.'), 200, 400);

            ctx.font = "bold 40px Roboto"

            ctx.fillStyle = "#fff";  //<======= here
            ctx.textAlign = "left";
            ctx.fillText(moment(date).format('MMM').toLowerCase(), 250, 400);

            ctx.font = "70px Roboto"
            ctx.fillStyle = "#fff";  //<======= here
            ctx.textAlign = "center";
            ctx.fillText("|", 350, 400);

            ctx.font = "bold 40px Roboto "

            ctx.fillStyle = "#337782";  //<======= here
            ctx.textAlign = "left";
            ctx.fillText(moment(date).format('HH:mm') + 'h', 370, 400);


            ctx.font = "32px Roboto"
            ctx.fillStyle = "#fff";  //<======= here
            ctx.textAlign = "center";
            ctx.fillText(location.split('\n')[0], 350, 480);
            ctx.font = "32px Roboto"
            ctx.fillStyle = "#fff";  //<======= here
            ctx.textAlign = "center";
            ctx.fillText(location.split('\n')[1], 350, 520);

            this.setState({
                image: canvas.toDataURL()
            })
        }

    }




    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    handleChange(date) {
        this.setState({
            startDate: date
        });
    }

    asd(event) {
        this.setState({ value: event.target.value });
    }


    render() {
        const buttonText = "JETZT RESERVIEREN";
        const buttonText3 = "HERUNTERLADEN";
        const buttonText4 = "SENDEN";

        let saveData = 'asd';
        return (
            <>
                <div className="header2">
                    {this.state.width > 991 ?
                        <img src={HeaderBackground2} className="headerBG" /> : <img src={MobileBackground} className="headerBG"></img>}
                </div>
                <Container className="aboutPage">
                    <Row noGutters className="d-block d-md-flex d-lg-flex justify-content-between universalTextPage align-items-center">
                        <div>
                            <h1>Kindergeburtstag</h1>
                        </div>
                        <div className="bredkramb">
                            <NavItem>
                                <Link to="/">Startseite</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/"> Kindergeburtstag</Link>
                            </NavItem>
                        </div>
                    </Row>
                    <Row>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <div className="text" dangerouslySetInnerHTML={{__html: this.props.pages['Kindergeburtstag'].shortDescription}}>


                            </div>
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <img src={this.props.pages['Kindergeburtstag'].image} className="img-fluid mt-4" />
                        </Col>
                    </Row>
                    
                        <div dangerouslySetInnerHTML={{__html: this.props.pages['Kindergeburtstag'].content}}>
                    </div>

                    <Container className="priceListPage">
                        <Row>
                            <h5>Unser Angebot für den kleinen Hunger</h5>
                        </Row>
                        <Row className="silverRow">
                            <Col xl="6" lg="6" md="6" xs="8">
                                <p>10 Portionen mittlere Pommes inkl. 10x Capri Sun (Mayonaise und Ketchup inkl.)</p>
                            </Col>
                            <Col xl="6" lg="6" md="6" xs="4">
                                <p className="text-right">für nur 26,50 €</p>
                            </Col>
                        </Row>
                        <Row className="silverRow">
                            <Col xl="6" lg="6" md="6" xs="6">
                                <p>Jede weitere Portion</p>
                            </Col>
                            <Col xl="6" lg="6" md="6" xs="6">
                                <p className="text-right">nur 2,50 </p>
                            </Col>
                        </Row>
                        <Row>
                            <h5>ODER</h5>
                        </Row>
                        <Row className="silverRow">
                            <Col xl="8" lg="8" md="8" xs="8">
                                <p>10 Portionen Chicken Nuggets (jeweils 4 Stück)<br />10 kleine Portionen Pommes<br />  (Mayonaise und Ketchup inkl.)</p>
                            </Col>
                            <Col xl="4" lg="4" md="4" xs="4">
                                <p className="text-right">für nur 47,- €</p>
                            </Col>
                        </Row>
                        <Row className="silverRow">
                            <Col xl="6" lg="6" md="8" xs="8">
                                <p>Jede weitere Portion</p>
                            </Col>
                            <Col xl="6" lg="6" md="4" xs="4">
                                <p className="text-right">nur 4,50 €</p>
                            </Col>
                        </Row>
                    </Container>

                    <p>(Diese Angebote sind nur gültig in Verbindung mit einer Tischreservierung.)</p>
                    {/* <p>Ihre Menübestellung machen Sie direkt vor Ort. Eine Vorabreservierung ist für das Menü nicht erforderlich.</p>
                    <p>Getränke bitte nur in Plastikflaschen / Tetrapack. Bitte haben Sie Verständnis, dass Lieferdienste (wie z.b. Pizzalieferungen) keinen Zutritt zur Halle erhalten.Tischfeuerwerk und Wunderkerzen sind leider verboten.
Diese können unsere Brandmeldeanlage auslösen.
                   </p>*/}


                    <div className="offer">
                        <h2>Reservierungsformular:</h2>
                    </div>
                    <div className="inputi">

                        {!this.state._done ?
                            <BirthdayForm onSubmit={(data) => {
                                data.date.setHours(data.from.split(':')[0]);
                                data.date.setMinutes(data.from.split(':')[1]);

                                this.props.core.birthday(data, () => {
                                    this.setState({
                                        _done: true,
                                        response: data
                                    }, () => {
                                        //date.date.setHours(data.from.split(':')[0]);
                                        //date.date.setMinutes(data.from.split(':')[0]);
                                        this.generateImage(data.name, data.date);
                                    })
                                })
                            }}></BirthdayForm>
                            :
                            <div>
                                <div class="alert alert-success mt-3 mb-5" role="alert">
                                    Die Nachricht wurde erfolgreich gesendet!
                                </div>
                                <Row className="d-flex justify-content-center">
                                    <Col xl="6" lg="6" md="6" xs="12">
                                        <canvas className="canvas-img" ref="canvas" width={700} height={980} />
                                        <img src={this.state.image} className="img-fluid" />

                                    </Col>
                                    <Col xl="12" className="d-flex justify-content-center">
                                        <div className="headerText">
                                            <a href={this.state.image} target="_blank" download="Glückwünsche.png">
                                                <MyButton buttonText={buttonText3} />
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        }

                    </div>


                </Container>
            </>
        )
    }
}

export default DefaultLayout(birthday)
