import React, { Component } from 'react'
import Navbar from '../containers/navbar';
import HeaderBackground2 from '../images/header2BG.png';
import { Link } from 'react-router-dom';
import GoogleMap from '../containers/map.js';
import Contact from '../containers/contact';
import Footer from '../containers/footer.js';
import CuurentInfoDetailPicture from '../images/infoDetailPicture.png';
import currentInformationPicture1 from '../images/aktuelne-informacije1.png';
import currentInformationPicture2 from '../images/aktuelne-informacije2.png';
import currentInformationPicture3 from '../images/aktuelne-informacije3.png';
import currentInformationPicture4 from '../images/aktuelne-informacije4.png';
import Isvg from 'react-inlinesvg';
import LeftIcon from '../images/svg/left-icon.svg';
import FutureEvents from '../containers/futureEvents';
import MyButton from '../containers/myButton';
import DefaultLayout from '../containers/defaultLayout';
import MobileBackground from '../images/mobileBG.png';


import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Input,
    NavItem
} from 'reactstrap';

class Jobs extends Component {
    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0, jobs: [] };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        this.props.core.fetchJobs((data) => {
            this.setState({ jobs: data })
        })
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    render() {
        const buttonText = "SENDEN";

        return (
            <>
                <div className="header2">
                    {this.state.width > 991 ? <img src={HeaderBackground2} className="headerBG" /> : <img src={MobileBackground} className="headerBG"></img>}
                </div>
                <Container>
                    <Row noGutters className="d-block d-md-flex d-lg-flex justify-content-between universalTextPage align-items-center">
                        <div>
                            <h1>Jobs</h1>
                        </div>
                        <div className="bredkramb">
                            <NavItem>
                                <Link to="/">Startseite</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/">Jobs</Link>
                            </NavItem>
                        </div>
                    </Row>
                    <Row>
                        <Col xl="12">
                            <h6 className="orangeh6">Offene Stellen</h6>
                        </Col>

                        {
                            this.state.jobs.map((item, idx) => {
                                return (
                                    <Col xl="6" lg="6" key={idx}>
                                        <div className="jobs">
                                            <h5>{item.title}</h5>
                                            <div dangerouslySetInnerHTML={{ __html: item.content }}>
                                            </div>
                                        </div>
                                    </Col>

                                )
                            })
                        }
                    </Row>

                </Container>
            </>
        )
    }
}

export default DefaultLayout(Jobs);
