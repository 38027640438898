import React, { Component } from 'react'
import currentInformationPicture1 from '../images/aktuelne-informacije1.png';
import currentInformationPicture2 from '../images/aktuelne-informacije2.png';
import currentInformationPicture3 from '../images/aktuelne-informacije3.png';
import currentInformationPicture4 from '../images/aktuelne-informacije4.png';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg'
import AllInfoButton from '../containers/allInfoButton';
import LeftIcon from '../images/svg/left-icon.svg';


import {
    Container,
    Row,
    Col,
    Button
} from 'reactstrap';

const buttonText = "Alle informationen";

class currentInformation extends Component {
    render() {
        return (
            <Container>
                <Row className="d-flex justify-content-center">

                    <div className="universalTitle">
                        <h3>AKTUELLE INFORMATIONEN</h3>
                    </div>
                </Row>

                <Row className="currentInformation">
                    {
                        this.props.items.map((item, idx) => {
                            return (
                                <Col xl="3" lg="3" md="6" xs="6" key={idx}>
                                <div className="currentInformationBox">
                                    <div className="currentInformationImage">
                                        <img src={item.image} className="img-fluid" />
                                    </div>
                                    <div className="currentInformationText">
                                        <p>{item.title}</p>
                                        <Link to={`/aktuelle-informationen/${item.title}/${item._id}`}>Mehr <Isvg src={LeftIcon} /></Link>
                                    </div>
                                </div>
                            </Col>
        
                            )
                        })
                    }

                    <Link to = "/aktuelle-informationen" className = "buttonWrap"><AllInfoButton buttonText={buttonText}></AllInfoButton></Link>
                </Row>
            </Container>

        )
    }
}

export default currentInformation
