import React, { Component } from 'react'
import Isvg from 'react-inlinesvg';
import logo from '../images/svg/logo.svg';
import facebookIcon from '../images/svg/facebook.svg';
import instagramIcon from '../images/svg/instagram.svg';
import { Link } from 'react-router-dom';
import { NavLink as RRNavLink } from 'react-router-dom';
import HeaderBackground2 from '../images/header2BG.png';
import { StickyContainer, Sticky } from 'react-sticky';

import {
    Container,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    Collapse,
    Navbar
} from 'reactstrap';

class Navigacija extends Component {
    constructor(props) {
        super(props);
        this.onScroll = this.onScroll.bind(this);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
            isTop: false
        };
    }


    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }
    componentDidMount() {
        document.addEventListener('scroll', () => {
            const isTop = window.scrollY > 0;
            if (isTop !== this.state.isTop) {
                this.onScroll(isTop);
            }

        });
    }
    onScroll(isTop) {
        this.setState({ isTop });

    }
    render() {
        return (
            <>
                <div className={this.state.isTop ? "klasa" : null}></div>
                <div className={this.state.isTop ? "fixed-top" : "klasa"}>
                    <Container className="NavigacijaWrapper">
                        <Row>
                            <Col xl="3" lg="3" md="4" xs="7">
                                <Link to="/"><Isvg src={logo} /></Link>
                            </Col>
                            <Col xl="9" lg="9" md="8" xs="5">
                                <Navbar className="navbar navbar-expand-lg static-top">
                                    <button className="btn-menu" onClick={() => this.setState({ isOpen: !this.state.isOpen })}><i class="fa fa-bars"></i></button>
                                    <Collapse isOpen={this.state.isOpen} navbar id="navbarResponsive">

                                        <NavItem>
                                            <NavLink exact tag={RRNavLink} className="nav-link" to="/" activeClassName="active">Startseite
                                        </NavLink>
                                        </NavItem>
                                       
                                    <NavItem>
                                        <NavLink tag={RRNavLink} className="nav-link" to="/eintrittspreise" activeClassName="active">Eintrittspreise</NavLink>
                                    </NavItem>
                                       
                                   
                                        <NavItem>
                                            <NavLink tag={RRNavLink} className="nav-link" to="/kindergeburtstag" activeClassName="active">Kindergeburtstag</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={RRNavLink} className="nav-link" to="/schulklassen" activeClassName="active">Schulklassen</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={RRNavLink} className="nav-link" to="/anmietung" activeClassName="active">Anmietung</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={RRNavLink} className="nav-link" to="/jobs" activeClassName="active">Jobs</NavLink>
                                        </NavItem>
                                    </Collapse>

                                </Navbar>

                            </Col>
                        </Row>
                        <div className="facebookIkonica">
                            <a href="https://www.facebook.com/eissporthalle.paradice/" target="_blank"><Isvg src={facebookIcon} /></a>
                        </div>
                        <div className="instagramIkonica">
                            <a href="https://www.instagram.com/paradice_eissporthalle/" target="_blank"><Isvg src={instagramIcon} /></a>
                        </div>
                    </Container>

                </div>
            </>
        )
    }
}

export default Navigacija
