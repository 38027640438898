import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';

import Navbar from '../containers/navbar.js';
import Footer from '../containers/footer.js';
import Contact from '../containers/contact.js';
import GoogleMap from '../containers/map.js';
import DefaultLayout from '../containers/defaultLayout';
import MobileBackground from '../images/mobileBG.png';


import HeaderBackground2 from '../images/header2BG.png';


import {
    Container, Row, Col, Button, FormGroup,
    Input, NavItem
} from 'reactstrap';
export class imprint extends Component {
    constructor(props) {
        super(props);

        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);



    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    render() {
        return (
            <>
                <div className="header2">
                    {this.state.width > 991 ? <img src={HeaderBackground2} className="headerBG" /> : <img src={MobileBackground} className="headerBG"></img>}
                </div>
                <Container className="aboutPage">
                    <Row noGutters className="d-block d-md-flex d-lg-flex justify-content-between universalTextPage align-items-center">
                        <div>
                            <h1>Impressum</h1>
                        </div>
                        <div className="bredkramb">
                            <NavItem>
                                <Link to="/">Startseite</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/"> Impressum</Link>
                            </NavItem>
                        </div>
                    </Row>

                    <div className="text border-bottom">

                        <p>Betriebsleitung der Eissporthalle und verantwortlich für den Inhalt dieser Seite:</p>

                        <p><b> Sport-Berntheusel GmbH</b> <br />
                            Waller Heerstr. 293a, 28219 Bremen, Deutschland<br />
                            Telefon: 0421 / 61 98 888<br />
                            E-Mail:       <span>info@eissporthalle-bremen.de</span><br />
                            Internet:   <span><a href="https://aamedia.rs/" target="_blank">www.eissporthalle-bremen.de</a></span></p>

                        <div className="offer">
                            <h2>Rechtliche Informationen</h2>
                        </div>

                        <p>Geschäftsführer: Uwe Kirsch und Anton Forster <br />
                            Rechtsform:  GmbH<br />
                            Register:  Handelsregister<br />
                            Register / Ort:  Amtsgericht Bremen<br />
                            Register-Nr.:  HRB 17694<br />
                            Umsatzsteuer ID:     DE 192682017</p>

                    </div>

                    <div className="text">
                        <p>Die Eislaufhalle Paradice ist ein Unternehmen der:</p>

                        <p> <b>Bremer Bäder GmbH </b><br />
                            Beim Ohlenhof 14<br />
                            28239 Bremen, Deutschland<br />
                            Telefon:   0421 / 69 15 10<br />
                            Telefax:   0421 / 69 15 140</p>

                        <div className="offer">
                            <h2>
                                Rechtliche Informationen
                    </h2>
                        </div>

                        Geschäftsführerin:  Frau Martina Baden<br />
                        Aufsichtsratsvorsitzender: Staatsrat Jan Fries<br />
                        Rechtsform:   GmbH<br />
                        Register:   Handelsregister<br />
                        Register / Ort:   Amtsgericht Bremen<br />
                        Register-Nr.:   HRB 3026<br />
                        Kammer:    IHK Bremen<br />
                        Umsatzsteuer ID:  <br />
                        DE 114439066<br />
                    </div>
                </Container>
                <GoogleMap {...this.props} />
                <Contact {...this.props} />
            </>
        )
    }
}

export default DefaultLayout(imprint)
