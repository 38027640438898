import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import Isvg from 'react-inlinesvg';
import arrowDown from '../../images/svg/arrowDown.svg';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

import {
    Container, Row, Col, Button, FormGroup,
    Input, NavItem
} from 'reactstrap';
import MyButton from '../myButton';
import { renderField, renderDateField, renderCheckField, renderTimeField } from './fields'

const required = value => value ? undefined : "Required"


const form = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    console.log(pristine, submitting);
    const buttonText = "JETZT RESERVIEREN";

    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col xl="6" lg="6" md="6" xs="12">
                    <FormGroup>
                        <Field validate={[required]} component={renderField} name="name" type="text" placeholder="Name der Schule" />
                    </FormGroup>
                </Col>
                <Col xl="6" lg="6" md="6" xs="12">
                    <FormGroup>
                        <Field validate={[required]} component={renderField} name="contactPerson" type="text" placeholder="Ansprechpartner:" />
                    </FormGroup>
                </Col>
                <Col xl="6" lg="6" md="6" xs="12">
                    <FormGroup>
                        <Field validate={[required]} component={renderField} name="phone" type="text" placeholder="Telefon für Rückfragen:" />
                    </FormGroup>
                </Col>
                <Col xl="6" lg="6" md="6" xs="12">
                    <FormGroup>
                        <Field validate={[required]} component={renderField} name="email" type="email" placeholder="Email:" />
                    </FormGroup>
                </Col>
                <Col xl="6" lg="6" md="6" xs="12">
                    <div className="selectFieldSingle">
                        <FormGroup>

                            <Field validate={[required]} component={renderDateField} name="date" placeholder="Choose date" />
                        </FormGroup>
                    </div>
                </Col>
                <Col xl="6" lg="6" md="6" xs="12">
                    <div className="d-flex selectField center-align">
                        <p>Uhrzeit von</p>
                        <FormGroup>
                            <Field validate={[required]} component={renderTimeField} name="from" type="text">
                            </Field>
                        </FormGroup>
                        <p>bis</p>
                        <FormGroup>
                            <Field validate={[required]} component={renderTimeField} type="text" name="to" id="exampleSelect">
                            </Field>
                        </FormGroup>
                    </div>
                </Col>
                <Col xl="6" lg="6" md="6" xs="12">
                    <FormGroup>
                        <Field validate={[required]} component={renderField} name="grade" type="text" placeholder="Jahrgangsstufe:" />
                    </FormGroup>
                </Col>
                <Col xl="6" lg="6" md="6" xs="12">
                    <FormGroup>
                        <Field validate={[required]} component={renderField} type="text" name="numberOfStudents" placeholder="Anzahl der Schüler:" />
                    </FormGroup>
                </Col>
                <Col xl="12" lg="12" md="12" xs="12">

                    <div className="d-flex input lastInput">
                        <p>Wir möchten Helme</p>
                        <FormGroup>
                            <Field validate={[required]} component={renderField} type="text" name="reserveHelmet" />
                        </FormGroup>
                        <p>reservieren</p>
                    </div>

                </Col>

                <Col xl="12" lg="12" md="12" xs="12">
                    <FormGroup>
                        <Field validate={[required]} component={renderField} name="remarks" type="textarea" placeholder="Bemerkungen:" />
                    </FormGroup>
                </Col>
                <div className="multiWrapper">
                    <MyButton buttonText={buttonText}></MyButton>
                </div>
            </Row>

        </form>
    )
}
export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
