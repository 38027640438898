import React, { Component } from 'react'
import Navbar from '../containers/navbar';
import HeaderBackground2 from '../images/header2BG.png';
import { Link } from 'react-router-dom';
import GoogleMap from '../containers/map.js';
import Contact from '../containers/contact';
import Footer from '../containers/footer.js';
import AboutPicture from '../images/aboutPicture.png';
import DefaultLayout from '../containers/defaultLayout';
import MobileBackground from '../images/mobileBG.png';
import ServicePicture1 from '../images/service1.png';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Input,
    NavItem
} from 'reactstrap';
class pricelist extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        this.state = { width: 0, height: 0, priceList: [], categories: [] };
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.props.core.fetchPriceList((data) => {
            this.setState({ priceList: data })
        })

        this.props.core.fetchCategories((data) => {
            console.log(data);
            this.setState({ categories: data })
        })

    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    render() {
        return (
            <>
                <div className="header2">
                    {this.state.width > 991 ? <img src={HeaderBackground2} className="headerBG" /> : <img src={MobileBackground} className="headerBG"></img>}
                </div>
                <Container className="priceListPage">
                    <Row noGutters className="d-block d-md-flex d-lg-flex justify-content-between universalTextPage align-items-center">
                        <div>
                            <h1>Eintrittspreise</h1>
                        </div>
                        <div className="bredkramb">
                            <NavItem>
                                <Link to="/"> Startseite</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/"> Eintrittspreise</Link>
                            </NavItem>
                        </div>
                    </Row>
                    <Row className="d-flex justify-content-center mb-4">
                        <Col xl="6">
                            <img src={ServicePicture1} className="img-fluid" />
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center">
                        <div className="universalTitle">
                            <h3>Eintrittspreise 2019 / 2020</h3>
                            <p>Gültig vom 01.09.2019 – 31.03.2020</p>
                        </div>
                    </Row>


                    {
                        this.state.categories.map((cat, idx) => {
                            return (
                                <>
                                    <Row>
                                        <Col xl="7" lg="7" md="8" xs="6">
                                            <h5>{cat.name}</h5>
                                        </Col>
                                        <Col xl="4" lg="4" md="4" xs="6" className=" offset-xl-1 offset-lg-1">
                                            <Row>
                                                <Col xl="12" lg="12" md="12" xs="12">
                                                    <h5>2019/20</h5>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    {
                                        this.state.priceList.map((item, idx) => {
                                            if (item.category === cat._id) {
                                                return (
                                                    <Row noGutters className="silverRow">
                                                        {
                                                            item.section.map((section, sidx) => {
                                                                return (
                                                                    <>
                                                                        <Col xl="6" lg="6" md="4" xs="4">
                                                                            <p className="marginParagraph">{section[0]}</p>
                                                                        </Col>
                                                                        <Col xl="4" lg="4" md="4" xs="3">
                                                                            <p>{section[1]}</p>
                                                                        </Col>
                                                                        <Col xl="2" lg="2" md="4" xs="5">
                                                                            <p className="last-parahraph">{section[2]}</p>
                                                                        </Col>

                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </Row>
                                                )
                                            }
                                        }
                                        )
                                    }
                                    {cat.subtitle ?
                                                           <Row noGutters>
                                                           <p className="italic">{cat.subtitle}</p>
                                                       </Row>
                                   
                                        :
                                        null
                                    }
                                </>
                            )
                        })
                    }



                    <Row noGutters className="d-flex justify-content-between">
                        <h6 className="orangeH6">*= Nur gegen Nachweis / Schwerbehinderte mit einem „B“ im Ausweis haben 1 Begleitung frei.</h6>
                        <h6 className="orangeH6">Unsere Öffnungszeiten finden Sie auf www.paradice.de</h6>
                    </Row>

                </Container>


                <GoogleMap {...this.props} />
                <Contact {...this.props} />
            </>
        )
    }
}

export default DefaultLayout(pricelist)
