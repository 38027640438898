import React, { Component } from 'react'
import Navbar from '../containers/navbar';
import HeaderBackground2 from '../images/header2BG.png';
import { Link } from 'react-router-dom';
import GoogleMap from '../containers/map.js';
import Contact from '../containers/contact';
import Footer from '../containers/footer.js';
import EventsPicture1 from '../images/events1.png';
import EventsPicture2 from '../images/events2.png';
import EventsPicture3 from '../images/events3.png';
import ReactPaginate from 'react-paginate';
import currentInformationPicture1 from '../images/aktuelne-informacije1.png';
import currentInformationPicture2 from '../images/aktuelne-informacije2.png';
import currentInformationPicture3 from '../images/aktuelne-informacije3.png';
import currentInformationPicture4 from '../images/aktuelne-informacije4.png';
import Isvg from 'react-inlinesvg'
import LeftIcon from '../images/svg/left-icon.svg';
import DefaultLayout from '../containers/defaultLayout';
import MobileBackground from '../images/mobileBG.png';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Input,
    NavItem
} from 'reactstrap';
class teamPage extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.state = {
            items: []
        }
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        this.props.core.fetchTeam((data) => {
            this.setState({
                items: data
            })
        })

    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    render() {
        const settings = {

            previousLabel: '',
            previousLinkClassName: 'no-ma',
            nextLabel: '',
            breakLabel: '...',
            breakClassName: 'break-me',
            pageCount: 9,
            marginPagesDisplayed: this.state.width <= 767 ? 2 : 3,
            pageRangeDisplayed: this.state.width <= 767 ? 1 : 2,
            onPageChange: (page) => { this.updateFilter('page', page.selected + 1) },
            pageClassName: 'all',
            pageLinkClassName: 'allLink',
            containerClassName: 'pagination',
            subContainerClassName: 'pages pagination',
            activeClassName: 'active',
            forcePage: this.state.page

        }
        return (
            <>
                <div className="header2">
                    {this.state.width > 991 ?
                        <img src={HeaderBackground2} className="headerBG" /> : <img src={MobileBackground} className="headerBG"></img>}
                </div>
                <Container className="eventDetailPage">
                    <Row noGutters className="d-block d-md-flex d-lg-flex justify-content-between universalTextPage align-items-center">
                        <div>
                            <h1>Teammitglieder</h1>
                        </div>
                        <div className="bredkramb">
                            <NavItem>
                                <Link to="/">Startseite</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/team">Teammitglieder</Link>
                            </NavItem>
                        </div>
                    </Row>
                    <Row>
                        {
                            this.state.items.map((item, idx) => {
                                return (
                                    <Col xl="4" lg="4" md="6" xs="6" key={idx}>
                                        <div className="currentInformationBox">
                                            <div className="currentInformationImage">
                                                <img src={item.image} className="img-fluid" />
                                                <span className="copy">{item.copyright}</span>
                                            </div>
                                            <div className="teamText">
                                                <h3>{item.name}</h3>
                                                <p>{item.description}</p>

                                            </div>
                                        </div>
                                    </Col>

                                )
                            })
                        }

                    </Row>
                    <Row noGutters>
                        {/*<ReactPaginate {...settings} />*/}

                    </Row>
                </Container>

            </>
        )
    }
}

export default DefaultLayout(teamPage)
