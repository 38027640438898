import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import Isvg from 'react-inlinesvg';
import arrowDown from '../../images/svg/arrowDown.svg';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

import {
    Container, Row, Col, Button, FormGroup,
    Input, NavItem
} from 'reactstrap';
import MyButton from '../myButton';
import { renderField, renderDateField, renderCheckField } from './fields'

const required = value => value ? undefined : "Required"



const form = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    console.log(pristine, submitting);
    const buttonText = "SENDEN";
    return (
        <form onSubmit={handleSubmit}>

            <FormGroup>
                <Field validate={[required]} component={renderField} type="text" name="name" placeholder="Name"></Field>
            </FormGroup>
            <FormGroup>
                <Field validate={[required]} component={renderField} type="email" name="email" placeholder="E-Mail Addresse"></Field>
            </FormGroup>
            <FormGroup>
                <Field validate={[required]} component={renderField} type="textarea" name="message" placeholder="Nachricht"></Field>
            </FormGroup>
            <div className="buttons">
                <MyButton buttonText={buttonText}>SENDEN</MyButton>
            </div>
        </form>
    )
}
export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
