import React, { Component } from 'react'
import Navbar from '../containers/navbar';
import HeaderBackground2 from '../images/header2BG.png';
import { Link } from 'react-router-dom';
import GoogleMap from '../containers/map.js';
import Contact from '../containers/contact';
import Footer from '../containers/footer.js';
import CuurentInfoDetailPicture from '../images/infoDetailPicture.png';
import currentInformationPicture1 from '../images/aktuelne-informacije1.png';
import currentInformationPicture2 from '../images/aktuelne-informacije2.png';
import currentInformationPicture3 from '../images/aktuelne-informacije3.png';
import currentInformationPicture4 from '../images/aktuelne-informacije4.png';
import Isvg from 'react-inlinesvg';
import LeftIcon from '../images/svg/left-icon.svg';
import FutureEvents from '../containers/futureEvents';
import MyButton from '../containers/myButton';
import DefaultLayout from '../containers/defaultLayout';
import MobileBackground from '../images/mobileBG.png';
import ServicePicture2 from '../images/anmietung.png';

import FreieZeiten from '../images/FreieZeiten.pdf';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Input,
    NavItem
} from 'reactstrap';
import LostAndFoundForm from '../containers/forms/lostAndFoundForm';

class anmietung extends Component {
    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0, sex: 'Frau' };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {
        const buttonText = "PDF HERUNTERLADEN";

        return (
            <>
                <div className="header2">
                    {this.state.width > 991 ? <img src={HeaderBackground2} className="headerBG" /> : <img src={MobileBackground} className="headerBG"></img>}
                </div>
                <Container className="anmietung">
                    <Row noGutters className="d-block d-md-flex d-lg-flex justify-content-between universalTextPage align-items-center">
                        <div>
                            <h1>ANMIETUNG</h1>
                        </div>
                        <div className="bredkramb">
                            <NavItem>
                                <Link to="/">Startseite</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/">Anmietung</Link>
                            </NavItem>
                        </div>
                    </Row>

                    <Row>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <div className="text" dangerouslySetInnerHTML={{__html: this.props.pages['Anmietung'].shortDescription}}>

                            </div>
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <img src={this.props.pages['Anmietung'].image} className="img-fluid mt-4" />
                        </Col>
                    </Row>

                     <div dangerouslySetInnerHTML={{__html: this.props.pages['Anmietung'].content}}>
                     </div>
                    <Row>
                       
                        <Col xl="4" lg="4" md="4">
                            <a href={FreieZeiten} target="_blank"><MyButton buttonText={buttonText} /></a>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default DefaultLayout(anmietung);
