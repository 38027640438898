import React, { Component } from 'react'
import Isvg from 'react-inlinesvg';
import ScrollIcon from '../images/svg/skrol.svg';
import ArrowLeft from '../images/svg/arrow-left.svg';
import ArrowRight from '../images/svg/arrow-right.svg';
import { Link } from 'react-router-dom'

import {
    Container,
    Row,
    Col,
    Button,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption

} from 'reactstrap';
import MyButton from './myButton';




const items = [
    {
        id: 1,
        day: 'HEUTE',
        von: '09:00',
        bis: '13:00',
        desc: 'Öffentlicher Lauf3'
    },
    {
        id: 2,
        day: 'HEUTE',
        von: '13:00',
        bis: '21:00',
        desc: 'Öffentlicher Lauf3'
    },
    {
        id: 3,
        day: 'HEUTE',
        von: '21:00',
        bis: '000',
        desc: 'Öffentlicher Lauf3'
    }
];

const items2 = [
    {
        id: 1,
        day: 'Donnerstag',
        von: '09:00',
        bis: '13:00',
        desc: 'asd'
    },
    {
        id: 2,
        day: 'Donnerstag',
        von: '13:00',
        bis: '21:00',
        desc: 'asd'
    },
    {
        id: 3,
        day: 'Donnerstag',
        von: '21:00',
        bis: '000',
        desc: 'asd'
    }
];


class TimeCarousel extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);

        this.state = {
            activeIndex: 0,
        }
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.props.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.props.items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex, });
    }


    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }



    render() {
        const { activeIndex } = this.state;
        const slides = this.props.items.map((item, idx) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={idx}
                    className="animated fadeIn"
                    

                >
                    <div className="boxTop">
                        <h4>{this.props.name}</h4>
                        <h6 className="mb-2">{this.props.date}</h6>
                        <h6>Von <span>{item.from}</span></h6>
                        <h6>Bis <span>{item.to}</span></h6>
                    </div>
                    <div className="boxBottom">
                        <p>{item.text}</p>
                    </div>
                    <div className="whiteDiv"></div>
                </CarouselItem >

            )
        })


        return (
            <div className={this.props.active ? 'workingHoursBox aktivna' : 'workingHoursBox'}>
                <Carousel
                    activeIndex={activeIndex}
                    next={this.next}
                    previous={this.previous}
                    interval={2000}
                >
                    {slides}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                </Carousel>
            </div>

        )
    }
}

class openingHours extends Component {
    constructor(props) {
        super(props);
        this.state = {
            days: []
        }


    }

    componentDidMount() {
        this.props.core.fetchWorkingTime((data) => {
            this.setState({
                days: data
            })
        })
    }

    render() {
        const buttonText = "Hier klicken für weitere Öffnungszeiten"
        if (!this.state.days.length) {
            return null;
        }

        return (
            <Container className="openingHours">
                <div className="d-flex justify-content-center">
                    <div>
                        <Isvg src={ScrollIcon} />
                        <h3>ÖFFNUNGSZEITEN</h3>
                    </div>
                </div>
                <Row>
                    <Col xl="12" lg="12" md="12" className="contents d-md-flex d-lg-flex justify-content-between">
                        {
                            this.state.days.map((item, idx) => {
                                return (
                                    <>
                                    {
                                        item.hours.length == 0 ?
                                        <TimeCarousel
                                                date={item.date}
                                                name={item.name}
                                                items={[{from: '-', to: '-', text: 'Geschlossen'}]}
                                                active={idx == 0 ? true : false}
                                            ></TimeCarousel>
                                            :
                                            <TimeCarousel
                                                date={item.date}
                                                name={item.name}
                                                items={item.hours}
                                                active={idx == 0 ? true : false}
                                            ></TimeCarousel>
                                    }
                                    </>
                                )
                            })
                        }
                    </Col>

                </Row>
                <Row noGutters className="d-flex justify-content-center mt-5">
                    <Link to="/offnungszeiten" className="opening-hours-button"><MyButton buttonText={buttonText} /></Link>
                </Row>
            </Container>
        )
    }
}

export default openingHours
