import socketIO from 'socket.io-client';
import 'moment/locale/de'  // without this line it didn't work

const moment = require('moment')
moment.locale('de')

const API_KEY = '5d331ab62ada4357038eb149';

export class Core {
    constructor(props) {
        this.socket = socketIO('https://socket.hugemedia.online');

    }


    fetchWorkingTime(callback) {


        let query = { $or: [] };

        let t = new Date(/*2019, 9, 5*/);

        for (let i = 0; i < 7; i++) {
            let today = new Date(t);
            today.setDate(t.getDate() + i);


            let dd = String(today.getDate())
            let mm = String(today.getMonth() + 1)
            let yyyy = today.getFullYear();

            query['$or'].push({ date: mm + '/' + dd + '/' + yyyy });
        }



        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchWorkingTime',
            query: query
        });

        this.socket.once('fetchWorkingTime', (data) => {

            let result = [];

            for (let i = 0; i < 7; i++) {
                let today = new Date(t);
                today.setDate(t.getDate() + i);


                let dd = String(today.getDate())
                let mm = String(today.getMonth() + 1)
                let yyyy = today.getFullYear();

                let date = mm + '/' + dd + '/' + yyyy
                let date2 = dd + '.' + mm + '.' + yyyy
                let res = [];
                for (let j = 0; j < data.length; j++) {
                    //data[i].timestamp
                    if (date == data[j].date) {
                        res.push(data[j]);
                    }
                }


                result.push({
                    name: i == 0 ? 'HEUTE' : moment.unix(Math.floor(today.getTime() / 1000)).format('dddd'),
                    date: date2,
                    hours: res
                })


            }


            callback(result);
        });
    }

    fetchPriceList(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchPriceList',
            query: {}
        });

        this.socket.once('fetchPriceList', (data) => {
            callback(data);
        });
    }

    fetchWorkingTimeBySeasion(callback) {

        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchWorkingTimeBySeasion',
            query: {}
        });

        this.socket.once('fetchWorkingTimeBySeasion', (data) => {

            callback(data);
        });
    }

    fetchLastInformations(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchLastInformations',
            query: {},
            sort: {position: 1},
            limit: 4
        });

        this.socket.once('fetchLastInformations', callback);
    }


    fetchLastEvents(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchLastEvents',
            query: {},
            limit: 3
        });

        this.socket.once('fetchLastEvents', callback);
    }

    fetchHome(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchHome',
            query: {},
        });

        this.socket.once('fetchHome', callback);
    }

    fetchPages(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchPages',
            query: {},
        });

        this.socket.once('fetchPages', callback);
    }


    fetchCategories(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchCategories',
            query: {},
        });

        this.socket.once('fetchCategories', callback);
    }


    fetchInformations(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchInformations',
            query: {},
            sort: {position: 1}
        });

        this.socket.once('fetchInformations', callback);
    }
    fetchJobs(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchJobs',
            query: {},
        });

        this.socket.once('fetchJobs', callback);
    }
    contact(data, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'contact',
            query: {},
            data: data
        });

        this.socket.once('contact', callback);
    }

    birthday(data, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'birthday',
            query: {},
            data: data
        });

        this.socket.once('birthday', callback);
    }



    school(data, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'school',
            query: {},
            data: data
        });

        this.socket.once('school', callback);
    }



    lostFound(data, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'lostFound',
            query: {},
            data: data
        });

        this.socket.once('lostFound', callback);
    }




    fetchInformationsItem(id, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchInformationsItem',
            query: { _id: id },
        });

        this.socket.once('fetchInformationsItem', callback);
    }
    fetchEventsItem(id, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchEventsItem',
            query: { _id: id },
        });

        this.socket.once('fetchEventsItem', callback);
    }



    fetchEvents(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchEvents',
            query: {},
        });

        this.socket.once('fetchEvents', callback);
    }

    faq(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'faq',
            query: {},
        });

        this.socket.once('faq', callback);
    }


    fetchShop(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchShop',
            query: {},
        });

        this.socket.once('fetchShop', callback);
    }



    fetchProducts(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchProducts',
            query: {}
        });

        this.socket.once('fetchProducts', callback);
    }


    fetchProduct(data, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchProduct',
            query: data
        });

        this.socket.once('fetchProduct', callback);
    }

    fetchSubproducts(data, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchSubproducts',
            query: data
        });

        this.socket.once('fetchSubproducts', callback);
    }

    fetchCertificates(data, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchCertificates',
            query: data
        });

        this.socket.once('fetchCertificates', callback);
    }


    fetchNews(data, sort, limit, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchNews',
            query: data,
            sort: sort,
            limit: limit
        });

        this.socket.once('fetchNews', callback);
    }

    fetchNewsItem(data, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchNewsItem',
            query: data,
        });

        this.socket.once('fetchNewsItem', callback);
    }

    fetchSlides(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchSlides',
            query: {},
            sort: { time: -1 }
        });

        this.socket.once('fetchSlides', callback);
    }

    fetchGallery(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchGallery',
            query: {},
        });

        this.socket.once('fetchGallery', callback);
    }

    fetchTeam(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchTeam',
            query: {},
        });

        this.socket.once('fetchTeam', callback);
    }

    contact(data, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'contact',
            query: {},
            data: data
        });

        this.socket.once('contact', callback);
    }
}