import React, { Component } from 'react'
import Navbar from '../containers/navbar';
import HeaderBackground2 from '../images/header2BG.png';
import { Link } from 'react-router-dom';
import GoogleMap from '../containers/map.js';
import Contact from '../containers/contact';
import Footer from '../containers/footer.js';
import EventsPicture1 from '../images/events1.png';
import EventsPicture2 from '../images/events2.png';
import EventsPicture3 from '../images/events3.png';
import ReactPaginate from 'react-paginate';
import DefaultLayout from '../containers/defaultLayout';
import MobileBackground from '../images/mobileBG.png';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Input,
    NavItem
} from 'reactstrap';
class events extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.state = {
            events: []
        }
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.props.core.fetchEvents((data) => {



            let events = [];
            for(let i=0;i<data.length;i++){
                let dateText = data[i].date;
                if (dateText[dateText.length -1 ] == '.'){
                    dateText = dateText.substring(0, dateText.length - 1);

                }

                let brokenDate = dateText.split('.');

                let month = brokenDate[1];
                let day = brokenDate[0];
                let year = brokenDate[brokenDate.length -1];
                if (year.length == 2){
                    year = '20'+year;
                }


                let date = new Date(year, month, day, 0, 0 , 0, 0);
                
                data[i].ts = date.getTime() / 1000;
                events.push(data[i]);

            }

            events.sort((a, b) => (a.ts > b.ts) ? 1 : -1)



            this.setState({
                events: events
            })
        })

    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    render() {
        const settings = {

            previousLabel: '',
            previousLinkClassName: 'no-ma',
            nextLabel: '',
            breakLabel: '...',
            breakClassName: 'break-me',
            pageCount: 9,
            marginPagesDisplayed: this.state.width <= 767 ? 2 : 3,
            pageRangeDisplayed: this.state.width <= 767 ? 1 : 2,
            onPageChange: (page) => { this.updateFilter('page', page.selected + 1) },
            pageClassName: 'all',
            pageLinkClassName: 'allLink',
            containerClassName: 'pagination',
            subContainerClassName: 'pages pagination',
            activeClassName: 'active',
            forcePage: this.state.page

        }
        return (
            <>
                <div className="header2">
                    {this.state.width > 991 ?
                        <img src={HeaderBackground2} className="headerBG" /> : <img src={MobileBackground} className="headerBG"></img>}
                </div>
                <Container className="eventsPage">
                    <Row noGutters className="d-block d-md-flex d-lg-flex justify-content-between universalTextPage align-items-center">
                        <div>
                            <h1>Veranstaltungen</h1>
                        </div>
                        <div className="bredkramb">
                            <NavItem>
                                <Link to="/">Startseite</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/">Veranstaltungen</Link>
                            </NavItem>
                        </div>
                    </Row>
                    <Row>
                        {
                            /* this.state.events.map((item, idx) => {
                                 return (
                                     <Col xl="4" lg="4" md="6" xs="6" key={idx}>
                                         <Link to={`/veranstaltungen/${item.title}/${item._id}`} className="text-decoration-none">
                                             <div className="futureEventsBox">
                                                 <div className="futureEventsBoxImage">
                                                     <img src={item.image} className="img-fluid" />
                                                 </div>
                                                 <div className="futureEventsInformationText">
                                                     <h4>{item.title}</h4>
                                                     <div className="d-flex justify-content-between">
                                                         <p>{item.date}</p>
                                                         <p>{item.tm}</p>
                                                     </div>
                                                 </div>
                                             </div>
                                         </Link>
                                     </Col>
 
                                 )
                             })*/
                        }

                        {
                            this.state.events.map((item, idx) => {
                                return (
                                    <Col lg="12" key={idx}>
                                        <Link to={`/veranstaltungen/${item.title}/${item._id}`} className="text-decoration-none">
                                            <Container>
                                                <Row className="eventsListItem">
                                                    <Col lg="3" className="event-img"> <img src={item.image} /></Col>
                                                    <Col lg="9">
                                                        <div className="futureEventsInformationText">
                                                            <h4>{item.title}</h4>
                                                            <div className="d-flex justify-content-between">
                                                                <p>{item.date}</p>
                                                                <p>{item.tm}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>

                                        </Link>
                                    </Col>

                                )
                            })
                        }

                    </Row>
                    <Row noGutters>
                        {/*<ReactPaginate {...settings} />*/}

                    </Row>
                </Container>
            </>
        )
    }
}

export default DefaultLayout(events)
