import React, { Component } from 'react'
import footerBG from '../images/footerBG.png';
import blueFacebook from '../images/svg/blueFacebook.svg';
import blueInstagram from '../images/svg/blueInstagram.svg';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg'
import logo from '../images/svg/logo.svg';
import bremer from '../images/bremer.png';

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Input,
    NavItem
} from 'reactstrap';

class footer extends Component {
    render() {
        return (
            <div className="footerWrapper">
                <Container className="footer">
                    <Row className="d-flex justify-content-between align-items-center">
                        <Col xl="3" lg="3" md="5" xs="10">
                            <Link to="/">
                                <Isvg src={logo} className="img-fluid logo" />
                            </Link>
                        </Col>
                        <Col xl="3" lg="9" md="7">
                            <p className="social">Folgen Sie Uns<a href="https://www.facebook.com/eissporthalle.paradice/" target="_blank"><Isvg src={blueFacebook}></Isvg></a><a href="https://www.instagram.com/paradice_eissporthalle/" target="_blank"><Isvg src={blueInstagram}></Isvg></a></p>
                        </Col>
                    </Row>
                    <Row noGutters className="d-block d-md-flex d-lg-flex justify-content-between links">
                        <NavItem>
                            <Link to="/" className="blueLink">Startseite</Link>
                        </NavItem>

                        
                        <NavItem>
                            <Link to="/eintrittspreise" className="blueLink">Eintrittspreise</Link>
                        </NavItem>
                       

                        <NavItem>
                            <Link to="/kindergeburtstag" className="blueLink">Kindergeburtstag</Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/schulklassen" className="blueLink">Schulklassen</Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/veranstaltungen" className="blueLink">Veranstaltungen</Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/anmietung" className="blueLink">Anmietung</Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/lost-and-found" className="blueLink">Lost&Found</Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/eissport-shop" className="blueLink">Sport-Shop</Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/jobs" className="blueLink">Jobs</Link>
                        </NavItem>
                    </Row>
                    <Row noGutters className="d-block d-md-flex d-lg-flex justify-content-between info">
                        <div className="d-flex">
                            <NavItem>
                                <Link to="/Impressum" className="silverLink">Impressum</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/datenschutzerklärung" className="silverLink">Datenschutz</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/team" className="silverLink">Team</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/faq" className="silverLink">FAQ</Link>
                            </NavItem>
                        </div>
                        <div className="d-flex">
                            <p className="orangeText">Die Eissporthalle Paradice ist ein Betrieb der Bremer Bäder <a href="https://www.bremer-baeder.de/" target="_blank"><img className="bremer" src={bremer} /></a></p>
                        </div>
                    </Row>
                    <hr />
                    <Row noGutters className="d-flex justify-content-between copyright">
                        <div><p>Copyright © Paradice Bremer Eissporthalle - 2019. All rights reserved.</p></div>
                        <div><p>Created by <a href="https://www.huge-media.de/">HUGE media</a></p></div>
                    </Row>
                </Container>
                <img src={footerBG} />
            </div>
        )
    }
}

export default footer
