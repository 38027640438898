import React, { Component } from 'react'
import Navbar from '../containers/navbar';
import HeaderBackground2 from '../images/header2BG.png';
import { Link } from 'react-router-dom';
import GoogleMap from '../containers/map.js';
import Contact from '../containers/contact';
import Footer from '../containers/footer.js';
import CuurentInfoDetailPicture from '../images/infoDetailPicture.png';
import currentInformationPicture1 from '../images/aktuelne-informacije1.png';
import currentInformationPicture2 from '../images/aktuelne-informacije2.png';
import currentInformationPicture3 from '../images/aktuelne-informacije3.png';
import currentInformationPicture4 from '../images/aktuelne-informacije4.png';
import Isvg from 'react-inlinesvg';
import LeftIcon from '../images/svg/left-icon.svg';
import FutureEvents from '../containers/futureEvents';
import DefaultLayout from '../containers/defaultLayout';
import MobileBackground from '../images/mobileBG.png';



import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Input,
    NavItem
} from 'reactstrap';
import 'moment/locale/de'  // without this line it didn't work

const moment = require('moment')
moment.locale('de')


class currentInformationDetail extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.state = {
            data: {},
            informations: [],
            events: []
        }
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.props.core.fetchLastInformations((data) => {
            this.setState({
                informations: data
            })
        })

        this.props.core.fetchInformationsItem(this.props[0].match.params.id, (data) => {

            this.setState({
                data: data
            })
        })

        this.props.core.fetchLastEvents((data) => {
            this.setState({
                events: data
            })
        })

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].match.params.id !== this.props[0].match.params.id) {
            this.props.core.fetchInformationsItem(this.props[0].match.params.id, (data) => {

                this.setState({
                    data: data
                })
            })

        }
    }


    render() {

        return (
            <>
                <div className="header2">
                    {this.state.width > 991 ?
                        <img src={HeaderBackground2} className="headerBG" /> : <img src={MobileBackground} className="headerBG"></img>}
                </div>
                <Container className="eventDetailPage">
                    <Row noGutters className="d-block d-md-flex d-lg-flex justify-content-between universalTextPage align-items-center">
                        <div>
                            <h1>Aktuelle informationen</h1>
                        </div>
                        <div className="bredkramb">
                            <NavItem>
                                <Link to="/">Startseite</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/">Aktuelle informationen</Link>
                            </NavItem>
                        </div>
                    </Row>
                    <Row>
                        <Col xl="8" lg="8" md="8" xs="12" className="middle">
                            <img src={this.state.data.image} className="img-fluid"></img>
                            <h4>{this.state.data.title}</h4>
                            <p className="orangeParagraf">{moment.unix(this.state.data.time).format('DD.MM.YYYY.')}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{moment.unix(this.state.data.time).format('hh:mm A')}</p>
                            <div dangerouslySetInnerHTML={{ __html: this.state.data.content }}></div>
                        </Col>
                        <Col xl="4" lg="4" md="4" xs="12">
                            <h3>AKTUELLE UND KÜNFTIGE VERANSTALTUNGEN</h3>
                            {
                                this.state.informations.map((item, idx) => {
                                    return (
                                        <div className="currentInformationBox" key={idx}>
                                            <div className="currentInformationImage">
                                                <img src={item.image} className="img-fluid" />
                                            </div>
                                            <div className="currentInformationText">
                                                <p>{item.title}</p>
                                                <Link to={`/aktuelle-informationen/${item.title}/${item._id}`}>Mehr <Isvg src={LeftIcon} /></Link>
                                            </div>
                                        </div>

                                    )
                                })
                            }
                        </Col>

                    </Row>

                </Container>
                <div className="noButtonWrapper">
                    <FutureEvents items={this.state.events} />
                </div>
            </>
        )
    }
}

export default DefaultLayout(currentInformationDetail);
